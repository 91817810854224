// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumPage,
  enumPagination,
  enumRoutes
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import {
  Card, CardContent,
  Grid, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MessageHelper } from "../../helpers/MessageHelper";
import FinTimeActions from "../../actions/FinTimeActions";
import ZCurrencyField from "../../controls/ZCurrencyField";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import FinHeaderButtons from "./FinHeaderButtons";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FinProjectHeader from "./FinProjectHeader";

const RecordDefault = {
  OfficeID: 1445,
  CountryIDs: "",
  Name: "",
  PhaseIDs: "",
  ActiveFL: "1",
  TypeOfContractID: 0,
  ExpirationContractDays: 0,

  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0,
  Year: 2024,
  Month: 1,
  Lock: false,
  OfficeName: "",
  VerifierName: "", //Verifier
};

const styles = {
  TDSmall: { mt: 0, mb: 0, pt: 0, pb: 0 },
};

export default function FinTimeManage() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});

  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsLocked, setIsLocked] = useState(false);

  const [TimeSummary, setTimeSummary] = useState({});
  const [TimeEntries, setTimeEntries] = useState([]);

  const { Year, Month, StaffID, SummaryID,showMsg } = useParams();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //za Navigacija
  const navigate = useNavigate();



  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const initData = () => {
    async function fetchData() {


      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = null;

      //Najprvo vidi dali doaga od payroll_verify
      let isNew =
        location.pathname
          .toLowerCase()
          .toString()
          .indexOf("fintime_new".toLowerCase()) > -1;


      if (isNew) {
        let payload = { ftsStaffID: StaffID, ftsMonth: Month, ftsYear: Year };
        responses = await new FinTimeActions().CreateAndEditAsync(payload); // await LookupActions.InitAsync();
      }
      else {
        let payload = { ftsTimeSummaryID: SummaryID };
        responses = await new FinTimeActions().EditAsync(payload); // await LookupActions.InitAsync();
      }

      if (!responses.error) {
        let timeData = responses[0].data;

        setTimeSummary(timeData);
        setTimeEntries(timeData.Entries);

        setIsLocked(timeData.ftsSubmittedFL);
        setIsInitDone(true);

        if (timeData.ftsSubmittedFL) {
          navigate(
            enumRoutes.Timesheet_FinTime_Preview.replace(
              ":SummaryID",
              SummaryID
            ).replace(":showMsg", 0)
          );
        }

        if (showMsg === "1") {
          let result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Timesheet has been rewritten and open for submission");
          setMessageData(result);
        }
        else if (showMsg === "2") {
          let result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Timesheet has been  open for submission");
          setMessageData(result);
        }

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };


  const handleSaveRows = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(
        enumPage.LoadStartWithMsg,
        null,
        "This process could take a while, please wait!"
      );

      setMessageData(result);

      let response = await new FinTimeActions().SaveAsync(TimeEntries);
      if (!response.error) {
        //await searchDataAsync();

        result = msgHelper.setPhase(enumPage.SaveSuccess);
        setMessageData(result);

        navigate(
          enumRoutes.Timesheet_FinTime_Preview.replace(
            ":SummaryID",
            SummaryID
          ).replace(":showMsg", 1)
        );

      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        // setRecord((a) => ({
        //   ...a,
        //   ValidationMessages: result.ValidationMessages,
        // })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };



  const handleExternalChangeRecord = (e) => {
    // //alert("handleExternalChangeRecord");
    // console.error("CALLED handleExternalChangeRecord");
    // const { name, value, primaryKey, text } = e.target; //vo name-ot go imame proektot
    // console.error(name + ":" + value + ":" + primaryKey);

    // //najdi go od nizata samiot record i updatetiraj go
    // let newRecords = [...Records]; //Records.map( x => ({...x}) )
    // let currentCheck = newRecords.find(
    //   (a) => a.fteTimeEntryID.toString() === primaryKey.toString()
    // );

    // if (name === "fbAmount") {
    //   currentCheck["fbMgmtFee"] = 0;
    // } else if (name === "fbMgmtFee") {
    //   currentCheck["fbAmount"] = 0;
    // }

    // //za vo koj red od nizata da go setira so findInex
    // let propertyIndex = newRecords.findIndex(
    //   (x) => x.fteTimeEntryID.toString() === primaryKey.toString()
    // );
    // newRecords[propertyIndex] = currentCheck;
    // setTimeEntries(newRecords);
  };

  const handleChangeRecord = (e) => {
    console.error("CALLED handleChangeRecord");
    const { name, value, primaryKey, text } = e.target; //vo name-ot go imame proektot
    console.error(name + ":" + value + ":" + primaryKey);

    //najdi go od nizata samiot record i updatetiraj go
    let newRecords = [...TimeEntries]; //Records.map( x => ({...x}) )
    let currentCheck = newRecords.find(
      (a) => a.fteTimeEntryID.toString() === primaryKey.toString()
    );
    currentCheck[name] = value;
    currentCheck["IsChanged"] = true;

    //za vo koj red od nizata da go setira so findInex
    let propertyIndex = newRecords.findIndex(
      (x) => x.fteTimeEntryID.toString() === primaryKey.toString()
    );
    newRecords[propertyIndex] = currentCheck;
    setTimeEntries(newRecords);
    console.error(" FINISHED handleChangeRecord");
  };


  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h3" align="center">
                {TimeSummary.TimesheetTitle}
              </Typography>


            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <RenderTable
        Records={TimeEntries}
        Record={Record}
        onHandleChange={handleChangeRecord}
        onExternalHandleChange={handleExternalChangeRecord}
        onHandleSave={handleSaveRows}
        MessageData={MessageData}
        IsLocked={IsLocked}
        
      />


    </Typography>
  );
}

const RenderTable = ({
  Records,
  Record,
  DataArray,
  onHandleChange,
  onAddRow,
  onExternalHandleChange,
  onHandleFocus,
  onHandleSave,
  MessageData,
  IsLocked,
  onExportExcel,
  onDeleteRow,
}) => {
  //Record = Pagination
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer
        sx={{ display: { xs: "block", sm: "block", md: "block" } }}
      >
        <Table
          sx={{ minWidth: 650, border: "1" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
         

            <TableRow>
              <TableCell sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  #
                </Typography>
              </TableCell>

              <TableCell>
                <Typography variant="h6" align="center">
                  Project
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Cost center
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Allocation (D)
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Reported Days
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  MGMT Fee
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Budget (D)
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Prev. Reported (D)
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Balance (D)
                </Typography>
              </TableCell>

            </TableRow>

            {/* {Records && Records.length > 0 && (
              <TableRow>
                <TableCell colSpan={9} align="right" sx={{ pr: 2 }}>
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={MessageData.Loading || IsLocked}
                    onClick={() => onHandleSave()}
                  >
                    Save All
                  </Button>
                </TableCell>
              </TableRow>
            )} */}
          </TableHead>
          <TableBody>
            {Records && Records.map((row, index) => (
              <RenderRow
                row={row}
                index={index}
                onHandleChange={onHandleChange}
                onDeleteRow={onDeleteRow}
                onExternalHandleChange={onExternalHandleChange}
                //onHandleFocus={onHandleFocus}
                //onHandleDelete={onHandleDelete}
                MessageData={MessageData}
                IsLocked={IsLocked}
              />
            ))}
          </TableBody>

          {/* PAGINATION FOOTER HERE */}
          {Records.length > 0 && (
            <TableFooter>
              <TableRow>
              <TableCell colSpan={4} align="right" sx={{ pr: 2 }}>
              {new CurrencyHelper().ToMoneyFormatDigits(
                Records.reduce((sum, row) => (sum = sum + parseFloat(row.CalcMonthPlannedPercent)),0)
                , 2)}%
                </TableCell>

              
                <TableCell colSpan={6} align="right" sx={{ pr: 2 }}>
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={MessageData.Loading || IsLocked}
                    onClick={() => onHandleSave()}
                  >
                    Validate Time Sheet
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    );
  }, [Records, MessageData]);
};

const RenderRow = ({
  row,
  index,
  onHandleChange,
  onExternalHandleChange,
  onDeleteRow,
  MessageData,
  IsLocked
}) => {
  //Record = Pagination

  return React.useMemo(() => {
    return (
      <TableRow
        key={row.eyEmployeeID}

      //sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell sx={[styles.TDSmall, { width: "3%" }]}>
          {index + 1}.
        </TableCell>

        <TableCell sx={[styles.TDSmall, { width: "10%" }]}>
          {row.fteProjectName}
        </TableCell>

        <TableCell sx={[styles.TDSmall, { width: "20%" }]}>
          {row.fteBudgetName}
        </TableCell>

        <TableCell sx={[styles.TDSmall]} align="right">
          {new CurrencyHelper().ToMoneyFormatDigits(row.CalcMonthPlannedPercent, 2)}%  
          ({new CurrencyHelper().ToMoneyFormatDigits(row.CalcMonthPlanned, 1)})  
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          <ZCurrencyField
            Id="fteMonthSpent"
            Value={row.fteMonthSpent}
            HandleChange={onHandleChange}
            ExternalHandleChange={onExternalHandleChange}
            PrimaryKey={row.fteTimeEntryID}
            Disabled={IsLocked}
            Digits="1"
          />
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.CalcBudgetMgmtFee, 0)}
        </TableCell>


        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.CalcYearPlanned, 1)}
        </TableCell>


        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.CalcPrevReportedMonth, 1)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "10%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.CalcBalanceMonth, 1)}
        </TableCell>

      </TableRow>
    );
  }, [
    row.fteTimeEntryID,
    row.fteMonthSpent
  ]);
};
