// @ts-check 
import { useEffect } from 'react';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MyText from '../../controls/MyText';
import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumLookupTypes, enumPage, enumPagination, enumRoutes, enumSharedMsgs } from '../../helpers/common.constants';
import SystemUserActions from '../../actions/SystemUserActions';
import Button from '@mui/material/Button';
import MyCheckList from '../../controls/MyCheckList';
import { Card, CardActions, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, useMediaQuery } from '@mui/material';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import LogHelper from '../../helpers/LogHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SystemLogActions from '../../actions/SystemLogActions';
import MyDate from '../../controls/MyDate';
import moment from 'moment';
import SystemLogBreadcrumbs from './SystemLogBreadcrumbs';


const RecordDefault = {
  PageSize: enumPagination.PageSize,
  DonorIDs: "",
  CountryIDs: "",
  RegionIDs: "",
  PhaseIDs: "",
  Years: new Date().getFullYear().toString(),
  DateFrom: moment(Date.now()).add(-1, 'months').format("YYYY-MM-DD"),
  DateTo: moment(Date.now()).format("YYYY-MM-DD")
};


export default function SystemLogSearch() {

  const [Record, setRecord] = useState(RecordDefault);
  const [StaffData, setStaffData] = useState([]);
  const [ActiveLogTypes, setActiveLogTypes] = useState([]);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za Responsive
  const largeScreen = useMediaQuery("(min-width:600px)");

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + '/' + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);



  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */
  const setPageMsg = (state, response, customSuccessMsg) => {

    if (state == enumPage.LoadStart) {
      setMessageData((a) => ({ ...a, Message: '', Loading: true }));
    }
    else if (state == enumPage.LoadSuccess) {
      setMessageData((a) => ({ ...a, Loading: false }));
    }
    else if (state == enumPage.LoadStartWithMsg) {
      setMessageData((a) => ({ ...a, Loading: true, Message: customSuccessMsg, HasInfo: true }));
    }
    else if (state == enumPage.LoadSuccessWithMsg) {
      setMessageData((a) => ({ ...a, Loading: false, Message: customSuccessMsg }));
    }
    else if (state == enumPage.ErrorServer) {
      if (response.ValidationMessages) {
        setRecord((prevState) => ({ ...prevState, ValidationMessages: response.ValidationMessages })); //za da se reflekgiraat po ZSNText,
      }
      setMessageData((a) => ({ ...a, HasError: true, Message: response.error_description, Loading: false }));
    }
    else if (state == enumPage.SaveSuccess) {
      setMessageData((a) => ({ ...a, Loading: false, HasError: false, Message: enumSharedMsgs.Save }));
    }
    else if (state == enumPage.ActionSuccessWithMsg) {
      setMessageData((a) => ({ ...a, Loading: false, HasError: false, Message: customSuccessMsg }));
    }
  };

  const initData = () => {
    async function fetchData() {

      //NAJVAZNO da napravi prvo reset na s'e
      setIsInitDone(false);
      setRecord(RecordDefault);
      setPageMsg(enumPage.LoadStart);

      let responses = await new SystemLogActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {

        let StaffData = responses[0].data;
        let ActiveLogTypesData = responses[1].data;
        setStaffData(StaffData);
        setActiveLogTypes(ActiveLogTypesData);

        //so ova ke povika search preku useEffect
        setIsInitDone(true);

        setPageMsg(enumPage.LoadSuccess);
      }
      else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }
    fetchData();
  };

  const searchData = () => {
    async function fetchData() {
      setPageMsg(enumPage.LoadStartWithMsg, null, "Please wait to get the latest changes from the system....");

      let responses = await new SystemLogActions().SearchAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;

        setRecords(records); //za da se reflekgiraat po ZSNText,
        setPageMsg(enumPage.LoadSuccessWithMsg, null, '');

      } else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }

    fetchData();
  };


  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };


  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <React.Fragment>
        <Button sx={{ ml: 3, mr: 2, mt: 3.5 }} variant="contained" color="success" size="small" disabled={MessageData.Loading} onClick={() => searchData()}>Search</Button>
      </React.Fragment>
    )
  };


  const styleTDHeader = { fontSize: 13, fontWeight: "bold" };
  const styleTD = { fontSize: 13 };

  const parseMessage = (msg) => {

    if (!msg) return "";
    let arrMsgs = msg.split('\\n');
    if (arrMsgs.length === 0) {
      return "";
    }
    //ako ima \n vo string-ot togas iterriraj 
    //dokolku e poslen item od array togas ne dodavaj break 
    //https://www.freecodecamp.org/forum/t/newline-in-react-string-solved/68484/6
    const output = arrMsgs.map((item, i) => {
      if (i === arrMsgs.length - 1) {
        return <div key={i}>{item.replace(", Column", "Column")}</div>;
      }
      else {
        return <div key={i}>{item.replace(", Column", "Column")}<br /></div>;
      }
    });
    return output;
  }

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">

      <SystemLogBreadcrumbs page={enumBreadcrumbPage.Search} disabled={MessageData.Loading} />

      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <MyCheckList Id='LogTypeCodes' Record={Record} PlaceHolder='Type of Logs' HandleChange={handleChange} Options={ActiveLogTypes} OptionsValue="LogTypeCode" OptionsText="LogTypeDescription" />

            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MyCheckList Id='StaffIDs' Record={Record} PlaceHolder='Created By' HandleChange={handleChange} Options={StaffData} OptionsValue="RowID" OptionsText="FullName" />

            </Grid>
            <Grid item xs={12} sm={12} md={2.2}>
              <MyDate Id='DateFrom' PlaceHolder='Date From' Record={Record} HandleChange={handleChange} />

            </Grid>
            <Grid item xs={12} sm={12} md={2.2}>
              <MyDate Id='DateTo' PlaceHolder='Date To' Record={Record} HandleChange={handleChange} />
            </Grid>
            <Grid>
              <ToolbarButtons />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} >
              <Typography variant="subtitle1" align='left'>Total Records found: {Records.length}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <TableContainer sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="h4" align="center">Created Date</Typography></TableCell>
              <TableCell><Typography variant="h4" align="center">Log Type Code</Typography></TableCell>
              <TableCell><Typography variant="h4" align="center">Type Of Action</Typography></TableCell>
              <TableCell><Typography variant="h4" align="center">Log Data</Typography></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow key={row.LogId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {index + 1}. {moment(row.LogDate).format("DD/MM/YYYY HH:mm")}
                </TableCell>
                <TableCell>
                  {row.LogTypeDescription}
                </TableCell>
                <TableCell>{row.ActionType}</TableCell>
                <TableCell>
                  <b>{row.ReferenceDesc}</b><br />
                  {parseMessage(row.LogData)}

                  <i>Created by: {row.CreatedBy}</i>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="body2" align='left' sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
        {Records.map((row, index) => (
          <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="h5">
                    Date:
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    {moment(row.LogDate).format("DD/MM/YYYY HH:mm")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="h5">
                    Code:
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    {row.LogTypeDescription}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="h5">
                    Action
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    {row.ActionType}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="h5">
                    Data
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    <b>{row.ReferenceDesc}</b><br />
                    {parseMessage(row.LogData)}
                    <i>Created by: {row.CreatedBy}</i>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Typography>
    </Typography>
  );
}

