import * as React from 'react';
import { Checkbox, TextField  } from '@mui/material';
import CurrencyHelper from '../helpers/CurrencyHelper';

//export interface IValidationMessage {controlId: string, message: string}
 
export default function ZCheckboxField(args) {
  let message = "";
  let controlId = args.Id;
  let disabled = args.Disabled;

  let primaryKey = args.PrimaryKey;

  //Vo zavisnot od Value i HasError se refreshira komponentata
  let inputValue = args.Value ?  args.Value : false;

  const [value, setValue] = React.useState(false); 

  //1 --> Go zapaluva pri sekoja promena na InputValue i go formatira vo EUR format 
  React.useEffect(() => {

    setValue(inputValue);
    
  }, [inputValue]); //default


  //MNOGU E VAZNO NA BLUR da proveris da ne VIKNAT od SAVE za da prvo se snimi vrednosta vo STATE pa duri togas da se vikne SAVE
  //bidejki taka nema da se vnese poslednata izmena
  const handleChange = (e) => {
    const { name, value,checked } = e.target;
    console.error(checked);
    //let currentValue = value === "on" ? false : true;
    
    setValue(checked);

    //args.HandleBlur(e);
    let customerArgs = { target: { name: args.Id, value: checked, primaryKey: primaryKey} };
    args.HandleChange(customerArgs);
    
  };

  return React.useMemo(() => {
    return (
        <Checkbox
        id={controlId}
        name={controlId}
          size="small"
          checked={value}
          disabled={disabled}
          onChange={handleChange}
        />
    );
  }, [controlId, value, disabled, message]);
};
