// @ts-check 
import { useEffect } from 'react';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumPage, enumPagination, enumSharedMsgs } from '../../helpers/common.constants';
import Button from '@mui/material/Button';
import { Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SystemLogActions from '../../actions/SystemLogActions';
import moment from 'moment';
import { MessageHelper } from '../../helpers/MessageHelper';


const RecordDefault = {
  PageSize: enumPagination.PageSize,
  DonorIDs: "",
  CountryIDs: "",
  RegionIDs: "",
  PhaseIDs: "",
  Years: new Date().getFullYear().toString(),
  DateFrom: moment(Date.now()).add(-1, 'months').format("YYYY-MM-DD"),
  DateTo: moment(Date.now()).format("YYYY-MM-DD")
};


export default function SystemLogDetail(args) {

  const [Record, setRecord] = useState(RecordDefault);
  const [StaffData, setStaffData] = useState([]);
  const [ActiveLogTypes, setActiveLogTypes] = useState([]);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  const [open, setOpen] = React.useState(args.IsOpen);

  //1 --> Go zapaluva pri sekoja promena na InputValue i go formatira vo EUR format 
  React.useEffect(() => {
    setOpen(open);
    setRecords([]);
  }, [open]); //default

  React.useEffect(() => {
    setOpen(args.IsOpen);

    if (args.IsOpen) {
      initData();
    }
  }, [args.IsOpen]); //default


  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */

  const initData = () => {
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      //NAJVAZNO da napravi prvo reset na s'e
      setIsInitDone(false);
      setRecord(RecordDefault);


      let responses = await new SystemLogActions().SearchByRecordAsync(args.Props); // await LookupActions.InitAsync();
      if (!responses.error) {

        const records = responses[0].data;

        setRecords(records); //za da se reflekgiraat po ZSNText,

        //so ova ke povika search preku useEffect
        setIsInitDone(true);

        
        if (records.length === 0) {
          //Load Finished
          result = msgHelper.setPhase(
            enumPage.ActionSuccessWithMsg,
            null,
            "We coudn't find any logging  data due the older records in the system"
          );
          setMessageData(result);
        }
        else {
          //Load Finished
          result = msgHelper.setPhase(enumPage.LoadSuccess);
          setMessageData(result);
        }

      }
      else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };



  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };


  //****************************************** */
  //GUI DISPLAY
  //****************************************** */


  const parseMessage = (msg) => {

    if (!msg) return "";
    let arrMsgs = msg.split('\\n');
    if (arrMsgs.length === 0) {
      return "";
    }
    //ako ima \n vo string-ot togas iterriraj 
    //dokolku e poslen item od array togas ne dodavaj break 
    //https://www.freecodecamp.org/forum/t/newline-in-react-string-solved/68484/6
    const output = arrMsgs.map((item, i) => {
      if (i === arrMsgs.length - 1) {
        return <div key={i}>{item.replace(", Column", "Column")}</div>;
      }
      else {
        return <div key={i}>{item.replace(", Column", "Column")}<br /></div>;
      }
    });
    return output;
  }

  //const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    args.HandleModalClose(true);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">System Log</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <MyAlert {...MessageData} />

            {Records.map((row, index) => (
              <Card sx={{ minWidth: 275, borderRadius: 2, mb: 1 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography variant="h5">
                        Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h6" sx={{ pl: 2.5 }}>
                        {moment(row.LogDate).format("DD/MM/YYYY HH:mm")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography variant="h5">
                        Code:
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h6" sx={{ pl: 2.5 }}>
                        {row.LogTypeDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography variant="h5">
                        Action
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h6" sx={{ pl: 2.5 }}>
                        {row.ActionType}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography variant="h5">
                        Data
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h6" sx={{ pl: 2.5 }}>
                        <b>{row.ReferenceDesc}</b><br />
                        {parseMessage(row.LogData)}
                        <i>Created by: {row.CreatedBy}</i>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="success" size="small" variant="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment> 
  );
}

