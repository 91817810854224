import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import  {createFilterOptions}  from '@mui/material/Autocomplete';


export default function ZDropDown(args) {

  let Record = args.Record;
  let required  = args.Required ?? false;
  let hasError  = false;
  let message  = '';
  let primaryKey = args.PrimaryKey;

  //Proveri dali ima greska
  if (required === true) {
    let errorCollection = args.ValidationMessages ? args.ValidationMessages : [];
    if (errorCollection != null && errorCollection.length > 0) {
      let currentOption = errorCollection.find((x) => x.controlId === args.Id);
      if (currentOption != null) {
        hasError = true;
        message = currentOption.message;
      }

      //sekogas resetiraj ja EMPTY bidejki ne ni treba a istata e povrzana za drugi komponenti poradi toa napravi nov set 
      //args.ValidationMessages = [];
    }
  }
  

  return React.useMemo(() => {

    let disabled  = args.Disabled ?? false;
    let value  = args.Value ?  args.Value : "";
    
    if (value === undefined || value === null)
    {
      value = "";
    }
    //value = "";
    
    //default ocekuva Text i Value za Select dokolku nema izbrano user-ot args.
    let optionText  = "Text";
    let optionValue  = "Value";
    let LookupTypeId  = args.LookupTypeId; //ako e praten ke go setira ako ne UNDEFINED
    let placeHolder  = args.PlaceHolder;
    let primaryKey = args.PrimaryKey;

    let options  = args.Options ? args.Options : [];

    //proveri dali e za Lookup togas znaci deka optionText = ldValue ; optionValue = ldLookupDataID
    if (LookupTypeId) {
      options = options.filter((x) => x.ldTypeID === LookupTypeId) || [];
      optionValue = "ldLookupDataID";
      optionText = "ldValue";
    }
    else {
      //slucajno ako vo arguments ima custom Value i Text togas zemi gi od tamu konkretno args.OptionsText i  args.OptionsValue
      if (args.OptionsText) {
        optionText = args.OptionsText;
      }
      if (args.OptionsValue) {
        optionValue = args.OptionsValue;
      }
    }

    //useCallback samo ednas da se mountira: https://everyday.codes/javascript/react-usememo-and-when-you-should-use-it/
    const handleSelectedValue = (event, value) => {
      
      let option  = value;
      if (option !== null) {

        let customerArgs = { target: { name: args.Id, 
                                      value: option[optionValue], 
                                      primaryKey: primaryKey,
                                      text: option[optionText] } };
        args.HandleChange(customerArgs);
      } else {
        let customerArgs = { target: { name: args.Id, 
                                      value: 0, 
                                      primaryKey: primaryKey, 
                                      text: "" } };
        args.HandleChange(customerArgs);
      }
    };

    //ovie se vrednostite sto ke se setiraat
    let selectedValueId = value; //gore "value" nikogas nema da bide NULL
    //if (!selectedValueId) selectedValueId = "";
    let selectedValueText = "";

    if (options && options.length > 0) {
        let currentOption = options.filter((x) => x[optionValue].toString() === selectedValueId.toString()) || [];
        if (currentOption.length > 0) {
          selectedValueText = currentOption[0][optionText];
        }
    }

    return (
      <Autocomplete
        size='small'
        id={args.Id}
        disabled={disabled}
        getOptionLabel={(option) => typeof option === "string" ? option : option[optionText]}
        onChange={(event, value) => { handleSelectedValue(event, value); }} //ako e MULTIPLE 
        options={options}
        //filterOptions={filterOptions}
        autoComplete
        value={selectedValueText}
        renderInput={(params) => (
          <TextField {...params} label={placeHolder} variant="outlined" fullWidth margin="dense" autoFocus={args.AutoFocus} helperText={message} error={hasError}
          />
        )}
      />
    );
  }, [args, hasError, message, args.Value]);
};

