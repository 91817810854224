// @ts-check 
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import MyText from '../../controls/MyText';
import MyDropDown from '../../controls/MyDropDown';

import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumLookupTypes, enumPage, enumSharedMsgs } from '../../helpers/common.constants';
import NotificationActions from '../../actions/NotificationActions';
import Button from '@mui/material/Button';
import MyCheckList from '../../controls/MyCheckList';
import { Grid } from '@mui/material';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from '../../helpers/CurrencyHelper';
import MyMoney from '../../controls/MyMoney';
import MyNumber from '../../controls/MyNumber';
import NotificationBreadcrumbs from './NotificationBreadcrumbs';
import MyDate from '../../controls/MyDate';
import moment from 'moment';
import MyDateTime from '../../controls/MyDateTime';

const RecordDefault = {
  noNotificationID: 0,
  noName: "",
  noDescription: "",
  noEmail: "",
  noEmailCC: "",
  noScheduledDate: moment(Date.now()).format("YYYY-MM-DD HH:mm")
};

export default function NotificationManage(args) {

  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [IsInitDone, setIsInitDone] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);
  const [ForceSave, setForceSave] = useState(false);


  const [LookupData, setLookupData] = useState([]);
  const [StaffData, setStaffData] = useState([]);
  const [YearsData, setYearsData] = useState([]);

  const [CalculationRecord, setCalculationRecord] = useState(null);
  const [CountryData, setCountryData] = useState([]);

  //Da proveri dali e nov zapis ili postoecki
  const { AcquisitionID } = useParams();

  //za Navigacija 
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za convertiranje na Pari
  let currencyHelper = new CurrencyHelper();

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + '/' + location.search]);

  //2 --> Ako e postoecki zapis povikaj editData  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {

      //Check if is existing record and make EditData
      if (AcquisitionID) {
        editData();
      }
    }
  }, [IsInitDone]);




  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */

  const setPageMsg = (state, response) => {

    if (state == enumPage.LoadStart) {
      setMessageData((a) => ({ ...a, Message: '', Loading: true }));
    }
    else if (state == enumPage.LoadSuccess) {
      setMessageData((a) => ({ ...a, Loading: false }));
    }
    else if (state == enumPage.ErrorServer) {
      if (response.ValidationMessages) {
        setRecord((prevState) => ({ ...prevState, ValidationMessages: response.ValidationMessages })); //za da se reflekgiraat po ZSNText,
      }
      setMessageData((a) => ({ ...a, HasError: true, Message: response.error_description, Loading: false }));
    }
    else if (state == enumPage.SaveSuccess) {
      setMessageData((a) => ({ ...a, Loading: false, HasError: false, Message: enumSharedMsgs.Save }));
    }
  };

  const initData = () => {
    async function fetchData() {

      setIsNewRecord(true); //DEFAULT 
      setRecord(RecordDefault);
      setPageMsg(enumPage.LoadSuccess)
    }

    fetchData();
  };


  const editData = () => {
    async function fetchData() {

      setPageMsg(enumPage.LoadStart);
      //setMessageData((a) => ({...a, Loading: true }));

      let payload = { acAcquisitionID: AcquisitionID };
      let responses = await new NotificationActions().EditAsync(payload); // await LookupActions.InitAsync();
      if (!responses.error) {

        let recordDb = responses[0].data;
        setRecord(recordDb);

        setPageMsg(enumPage.LoadSuccess);
        setIsNewRecord(false);
      }
      else {
        setPageMsg(enumPage.ErrorServer, responses);
      }

    }
    fetchData();
  };

  const saveData = () => {
    console.error("saveData");
    async function fetchData() {
      setMessageData((a) => ({ Loading: true }));

      //mora za da moze pravilno da se snimi inaku pravi Validation Error: Status Code: 400
      Record.noScheduledDate = moment(Record.noScheduledDate).format("YYYY-MM-DDTHH:mm:ss.sssZ");

      let response = await new NotificationActions().SaveAsync(Record);

      if (!response.error) {

        let recordDb = response[0].data;
        setRecord(recordDb);

        //ke go prati do App.js na event handler: const handleRefresh = (argsOfRecordData) 
        //od tamu ke go prati vo backContainer  pa vo Header js kako recordData={recordData}
        //za da go promeni header-ot od New Lead vo Manage Lead 
        if (IsNewRecord) {
          args.onRefresh({ refreshFL: true, record: recordDb });
        }

        setPageMsg(enumPage.SaveSuccess);
        setIsNewRecord(false);
      }
      else {
        setPageMsg(enumPage.ErrorServer, response);
      }

      setForceSave(false);
    }

    fetchData();
  };

  const goToNotes = (Id) => {
    navigate("/Acquisition/Notes/Edit/" + Id);
  };



  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  // const handleChangeDateTime = (e) => {
  //   const { name, value } = e.target;

  //   let formattedValue = "";
  //   if (value.length === 5) //it is time 
  //   {
  //     let currentDate = Record[name];
  //     currentDate = moment(currentDate).format("YYYY-MM-DD")
  //     currentDate = currentDate + " " + value;
  //     formattedValue = moment(currentDate).format("YYYY-MM-DD HH:mm")
  //   }
  //   else {
  //     let currentTime = Record[name];
  //     currentTime = moment(currentTime).format("HH:mm");
  //     let currentDate = value + " " + currentTime;
  //     formattedValue = moment(currentDate).format("YYYY-MM-DD HH:mm")
  //   }

  //   if (formattedValue === "Invalid date")
  //   {
  //     formattedValue = moment(Date.now()).format("YYYY-MM-DD HH:mm");
  //   }
    
  //   setRecord((prevState) => ({ ...prevState, [name]: formattedValue }));
  // };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          <Button name="btnSave" variant="contained" sx={{ mr: 1 }} color="success" size="small" disabled={MessageData.Loading} onClick={() => saveData()}>Save</Button>
        </Grid>
      </Grid>
    )
  };
  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">

      <NotificationBreadcrumbs page={enumBreadcrumbPage.Master} Id={Record.noNotificationID} disabled={MessageData.Loading} />

      <MyAlert {...MessageData} />

      <ToolbarButtons />


      {!IsNewRecord &&
        <Grid container align="center">
          <Grid item xs={12}>
            <Typography variant="h6" display="block">{Record.noName}</Typography>
          </Grid>
        </Grid>
      }

      <Grid container>
        <Grid item xs={12} align="left">
          <MyDateTime Id='noScheduledDate' PlaceHolder='Scheduled Date' Record={Record}
            HandleChange={handleChange}
          />
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12}>
          <MyText Id='noName'
            PlaceHolder='Name'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText Id='noDescription'
            PlaceHolder='Description'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            MultiLine={true}
            Rows={3}
            Required={true}
          />
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12}>
          <MyText Id='noEmail'
            PlaceHolder='Email'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
          />
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12}>
          <MyText Id='noEmailCC'
            PlaceHolder='CC Email'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
          />
        </Grid>
      </Grid>


      <ToolbarButtons />

      <MyAlert {...MessageData} />


    </Typography>
  );
}
