import * as React from 'react';
import Alert from '@mui/material/Alert';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@mui/material';

export interface Props {
  HandleModalClose(e: any): void,
  Title?: string,
  Content?: string,
  IsOpen: boolean
};

export default function MyDialogYesNo(args: Props) {


  const [open, setOpen] = React.useState(args.IsOpen);


  //1 --> Go zapaluva pri sekoja promena na InputValue i go formatira vo EUR format 
  React.useEffect(() => {
    setOpen(open);
  }, [open]); //default

  React.useEffect(() => {
    setOpen(args.IsOpen);
  }, [args.IsOpen]); //default


  const handleNo = (e: any) => {
    args.HandleModalClose(false);
    setOpen(false);
  };

  const handleYes = (e: any) => {
    args.HandleModalClose(true);
    setOpen(false);
  };


  return (
    <Dialog open={open}>
      <DialogTitle>{args.Title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {args.Content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleYes}>Yes</Button>
        <Button onClick={handleNo}>No</Button>
      </DialogActions>
    </Dialog>
  )
}