// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumData,
  enumPage,
  enumPagination,
  enumRoutes,
  enumSQLS,
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import MyDropDown from "../../controls/MyDropDown";
import { MessageHelper } from "../../helpers/MessageHelper";
import FinTimeActions from "../../actions/FinTimeActions";
import moment from "moment";
import MyAutoSuggestMulti from "../../controls/MyAutoSuggestMulti";
import DownloadActions from "../../actions/DownloadActions";

const RecordDefault = {
  PageSize: enumPagination.PageSize,
  StatusID: "0", //Submitted
  Year: 2024,
  Month: 0,
};

export default function FinTimeSearch() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      setIsInitDone(true);

      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    }
    fetchData();
  };

  const searchData = () => {
    async function fetchData() {
      //Load Start
      setRecords([]);
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new FinTimeActions().SearchAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;

        setRecords(records);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };
  const exportData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new FinTimeActions().ExportAsync(Record);

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  // ToolbarButtons component with adjusted layout
  const ToolbarButtons = () => {
    return (
      <Grid
        container
        spacing={1} // Adjust spacing between buttons
        alignItems="left"
        //justifyContent="flex-end" // Align buttons to the right
      >
        <Grid item>
          <Button
            variant="contained"
            color="success"
            size="medium"
            disabled={MessageData.Loading}
            onClick={() => searchData()}
            sx={{ mr: 1 }} // Adjust margin to the right of the Search button
          >
            Search
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            disabled={MessageData.Loading}
            onClick={() => exportData()}
          >
            Export
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={1}>
              <MyDropDown
                Id="Year"
                Record={Record}
                PlaceHolder="Year"
                HandleChange={handleChange}
                Options={enumData.YEARS}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="Month"
                Record={Record}
                PlaceHolder="Month"
                HandleChange={handleChange}
                Options={enumData.MONTHS}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <MyAutoSuggestMulti
                Id="StaffIDs"
                Record={Record}
                PlaceHolder="Employees"
                HandleChange={handleChange}
                SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
                SQLGetRecord={enumSQLS.KEY_GET_MULTI_Employee}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="StatusID"
                Record={Record}
                PlaceHolder="Status"
                HandleChange={handleChange}
                Options={enumData.FINTIME_STATUSES}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <MyAutoSuggestMulti
                Id="ProjectIDs"
                Record={Record}
                PlaceHolder="Projects"
                HandleChange={handleChange}
                SQLExtraParams={Record.Year} //go pusta parametarot za Search
                //ValidationMessages={Record.ValidationMessages}
                SQLGetList={enumSQLS.KEY_LIST_FinProjectSearchActive} //cita od LOOKUP
                SQLGetRecord={enumSQLS.KEY_GET_MULTI_FinProject} //cita od LOOKUP
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2} mt={1}>
              <ToolbarButtons />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Records.length}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <RenderTable Records={Records} />
    </Typography>
  );
}

const RenderTable = ({ Records }) => {
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer
        sx={{ display: { xs: "block", sm: "block", md: "block" } }}
      >
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h4" align="center">
                  Employee
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Month/Year
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Modified
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Modified By
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                key={row.rqRequestID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {index + 1}. {row.ftsStaffName}
                </TableCell>

                <TableCell>
                  {row.ftsMonth} / {row.ftsYear}
                </TableCell>

                <TableCell>
                  {row.ftsSubmittedFL ? "Submitted" : "Open"}
                </TableCell>

                <TableCell align="right">
                  {moment(row.ftsDateUpdate).format("DD/MM/YYYY")}
                </TableCell>

                <TableCell>{row.ftsUpdatedBy}</TableCell>

                <TableCell align="center">
                  <Tooltip
                    title={
                      "Preview of the Timesheet for " +
                      row.ftsMonth +
                      "/" +
                      row.ftsYear
                    }
                  >
                    <IconButton
                      size="small"
                      name="details"
                      onClick={() =>
                        navigate(
                          enumRoutes.Timesheet_FinTime_PreviewAdmin.replace(
                            ":SummaryID",
                            row.ftsTimeSummaryID
                          ).replace(":showMsg", "0")
                        )
                      }
                    >
                      <EditIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};
