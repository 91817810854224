import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { IValidationMessage } from '../helpers/common.interfaces';

export interface Props {
  Record: {},
  Id: string,
  Required?: boolean,
  Disabled?: boolean,
  PlaceHolder: string,
  Label?: string,
  AutoFocus?: boolean,
  HandleChange(e: { target: { name: string, value: string } }): void,
  ValidationMessages?: IValidationMessage[],
  LookupTypeId?: number,
  Options: any[],
  OptionsText?: string,
  OptionsValue?: string
  SelectedValue: string
};


export default function MyCheckList(args: Props) {

  let Record = args.Record;
  let required: boolean = args.Required ?? false;
  let hasError: boolean = false;
  let message: string = '';


  //Proveri dali ima greska
  if (required === true) {
    let errorCollection = args.ValidationMessages ? args.ValidationMessages : [];
    if (errorCollection != null && errorCollection.length > 0) {
      let currentOption = errorCollection.find((x) => x.controlId === args.Id);
      if (currentOption != null) {
        hasError = true;
        message = currentOption.message;
      }

      //sekogas resetiraj ja EMPTY bidejki ne ni treba a istata e povrzana za drugi komponenti poradi toa napravi nov set 
      //args.ValidationMessages = [];
    }
  }



  return React.useMemo(() => {

    // console.error("REFRESHED");
    // console.error("AAAAAAA");
    // console.error(args);


    let disabled: boolean = args.Disabled ?? false;
    // let controlId: string = args.Id;



    //Vo zavisnot od Value i HasError se refreshira komponentata
    //let values: string  = args.SelectedValue;
    let values : string = Record ? Record[args.Id as keyof {}] : "";



    //default ocekuva Text i Value za Select dokolku nema izbrano user-ot args.
    let optionText: string = "Text";
    let optionValue: string = "Value";
    let LookupTypeId: number | undefined = args.LookupTypeId; //ako e praten ke go setira ako ne UNDEFINED
    let placeHolder: string = args.PlaceHolder;

    let options: any[] = args.Options ? args.Options : [];

    //proveri dali e za Lookup togas znaci deka optionText = ldValue ; optionValue = ldLookupDataID
    if (LookupTypeId) {
      options = options.filter((x: any) => x.ldTypeID === LookupTypeId) || [];
      optionValue = "ldLookupDataID";
      optionText = "ldValue";
    }
    else {
      //slucajno ako vo arguments ima custom Value i Text togas zemi gi od tamu konkretno args.OptionsText i  args.OptionsValue
      if (args.OptionsText) {
        optionText = args.OptionsText;
      }
      if (args.OptionsValue) {
        optionValue = args.OptionsValue;
      }
    }


    //useCallback samo ednas da se mountira: https://everyday.codes/javascript/react-usememo-and-when-you-should-use-it/
    const handleSelectedValue = (event: React.SyntheticEvent, values: any) => {

      let selectValues = '';
      values.map((option : any) => {
        const selectedValue = option[optionValue].toString();
        if (selectValues === '') {
          selectValues = selectedValue;
        } else {
          selectValues += "," + selectedValue;
        }
      });      
      let customerArgs = { target: { name: args.Id, value: selectValues} };
      args.HandleChange(customerArgs);
    };

    let selectedValues = [];
    let arrSelectedValues = [];
    
    if (values) {
      //284, 285
      selectedValues = values.split(","); //Od string 2,3,4 vo niza [2],[3], [4]
      for (let i = 0; i <= selectedValues.length - 1; i++) {
        const currentSelectedValue = selectedValues[i]; //284
        //alert(currentSelectedValue);
        const currentItem = options.find((a) => a[optionValue].toString() === currentSelectedValue.toString());
        if (currentItem) {
          arrSelectedValues.push(currentItem);
        }
      }
    }

    
    return (
      <Autocomplete
        size='small'
        multiple={true}
        id={args.Id}
        disabled={disabled}
        getOptionLabel={(option) => typeof option === "string" ? option : option[optionText]}
        onChange={(event: React.SyntheticEvent, value: any[]) => { handleSelectedValue(event, value); }} //ako e MULTIPLE 
        options={options}
        autoComplete
        value={arrSelectedValues}
        
        renderInput={(params) => (
          <TextField {...params} label={placeHolder} variant="outlined" fullWidth margin="dense" autoFocus={args.AutoFocus} helperText={message} error={hasError}
          />
        )}
      />
    );
  }, [args, hasError, message]);
};

