// @ts-check 
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MyText from '../../controls/MyText';
import MyDropDown from '../../controls/MyDropDown';
import MySearchFilter from '../../controls/MySearchFilter';
import MySearchDialog from '../../controls/MySearchDialog';

import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumLookupTypes, enumPage, enumSharedMsgs } from '../../helpers/common.constants';
import AcquisitionActions from '../../actions/AcquisitionActions';
import Button from '@mui/material/Button';
import MyCheckList from '../../controls/MyCheckList';
import { Grid } from '@mui/material';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from '../../helpers/CurrencyHelper';
import MyMoney from '../../controls/MyMoney';
import MyNumber from '../../controls/MyNumber';
import SystemUserActions from '../../actions/SystemUserActions';
import MyCheckbox from '../../controls/MyCheckbox';
import UserActions from '../../actions/UserActions';


const RecordDefault = {
  Id: '',
  UserName: "",
  FullName: "",
  Email: "",
  Password: "",
  ConfirmPassword:"",
  OldPassword: "",
  IsEnabled: true,
  Role: "",
  RowID: 0
};

export default function UserChangePassword(args) {

  // console.error("AcquisitionManage - ARGS");
  // console.error(args);

  //Page MAIN Hooks
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);


  //Da proveri dali e nov zapis ili postoecki
  const { UserId } = useParams();

  //za Navigacija 
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();


  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    editData();
  }, [location.pathname + '/' + location.search]);

  

  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */

  const setPageMsg = (state, response) => {

    if (state == enumPage.LoadStart) {
      setMessageData((a) => ({ ...a, Message: '', Loading: true }));
    }
    else if (state == enumPage.LoadSuccess) {
      setMessageData((a) => ({ ...a, Loading: false }));
    }
    else if (state == enumPage.ErrorServer) {
      if (response.ValidationMessages) {
        setRecord((prevState) => ({ ...prevState, ValidationMessages: response.ValidationMessages })); //za da se reflekgiraat po ZSNText,
      }
      setMessageData((a) => ({ ...a, HasError: true, Message: response.error_description, Loading: false }));
    }
    else if (state == enumPage.SaveSuccess) {
      setMessageData((a) => ({ ...a, Loading: false, HasError: false, Message: enumSharedMsgs.Save }));
    }
  };



  const editData = () => {
    async function fetchData() {

      setPageMsg(enumPage.LoadStart);
      //setMessageData((a) => ({...a, Loading: true }));

      let payload = { Id: UserId };
      let responses = await new UserActions().GetUserDetails(); // await LookupActions.InitAsync();
      if (!responses.error) {

        let recordDb = responses[0].data;
        setRecord(recordDb);

        setPageMsg(enumPage.LoadSuccess);
      }
      else {
        setPageMsg(enumPage.ErrorServer, responses);
      }

    }
    fetchData();
  };

  const saveData = () => {
    async function fetchData() {
      setPageMsg(enumPage.LoadStart);

      let response = await new UserActions().ChangePasswordAsync(Record);

      if (!response.error) {

        setPageMsg(enumPage.SaveSuccess);

        setMessageData((a) => ({ ...a, Message: a.Message + "\n" + "Your new password is:" + Record.Password }));
      }
      else {
        setPageMsg(enumPage.ErrorServer, response);
      }
    }

    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };



  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">

      <MyAlert {...MessageData} />

      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          <Button variant="contained" color="success" size="small" disabled={MessageData.Loading} onClick={() => saveData()}>Save</Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" display="block" align="center">{Record.FullName}</Typography>
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12}>
          <MyText Id='Password'
            PlaceHolder='Enter your newer password'
            Type='password'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
          />
        </Grid>
      </Grid>
      
      
      <br/>

      <MyAlert {...MessageData} />


    </Typography>
  );
}
