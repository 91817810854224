import * as React from 'react';
import { TextField,TextFieldPropsSizeOverrides } from '@mui/material';
import { IValidationMessage } from '../helpers/common.interfaces';
import { OverridableStringUnion } from '@mui/types';
import CurrencyHelper from '../helpers/CurrencyHelper';

//export interface IValidationMessage {controlId: string, message: string}

export interface Props  {
  Record: {},
  Id: string,
  Required?: boolean,
  Disabled?: boolean,
  PlaceHolder: string,
  Type?: string,
  Label?: string,
  AutoFocus?: boolean,
  HandleChange(e: React.ChangeEvent<HTMLInputElement>) : void,
  HandleBlur(e: React.FocusEvent<HTMLInputElement>) : void,
  HandleFocus(e: React.FocusEvent<HTMLInputElement>) : void,
  ValidationMessages?: IValidationMessage[],
  MultiLine?: boolean
  Rows?: number
  TextAlign? : string
  Size? : string,
  IsMoney? : boolean
};


export default function MyText (args : Props) {
  let hasError = false;
  let message = "";
  let Record = args.Record;
  let controlId = args.Id;
  let required = args.Required;
  let disabled = args.Disabled;
  let placeHolder = args.PlaceHolder;
  let type = "text";
  let isMultiLine  = args.MultiLine ? args.MultiLine : false;
  let rows = args.Rows;
  let textAllignment = args.TextAlign;
  let size = args.Size ? args.Size : 'small';
  let IsMoney = args.IsMoney;

  if (args.Type) type = args.Type;

  
  //Vo zavisnot od Value i HasError se refreshira komponentata
  let value = Record ? Record[args.Id as keyof {}] : "";
  
  
  //Proveri dali ima greska
  if (required === true) {
    let errorCollection = args.ValidationMessages;
    if (errorCollection != null && errorCollection.length > 0) {
      let currentOption = errorCollection.find((x : IValidationMessage) => x.controlId === controlId); //vidi vo validation.helper.js
      if (currentOption != null) {
        hasError = true;
        message = currentOption.message;
      }
    }
  }


  //console.error("ALLOO OUT :" + new Date().getTime());
    

  return React.useMemo(() => { 

    //console.error("ALLOO:" + new Date().getTime());

    return (
      
      <TextField 
        type={type}
        //size={size = 'small' ? 'small' : 'medium'}
        size='small'
        variant={"outlined"}
        margin="dense" //normal
        fullWidth
        disabled={disabled}
        id={controlId}
        name={controlId}
        //helperText={args.Help}
        label={placeHolder}
        autoFocus={args.AutoFocus}
        onChange={args.HandleChange}
        onBlur={args.HandleBlur}        
        onFocus={args.HandleFocus}
        //onChange={e => dispatch({ type: 'UserName_Change', payload: e.target.value })}
        value={value}
        //defaultValue=" "
        helperText={message}
        error={hasError}
        placeholder={placeHolder}
        multiline={isMultiLine}
        rows={rows}
        sx={{input: {textAlign: textAllignment ? textAllignment : "left"}}}
      /> 
    );
  }, [controlId, value, message, disabled, hasError]);  
};
