// @ts-check 
import { useEffect } from 'react';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MyText from '../../controls/MyText';
import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumLookupTypes, enumPage, enumPagination, enumSharedMsgs } from '../../helpers/common.constants';
import SystemUserActions from '../../actions/SystemUserActions';
import Button from '@mui/material/Button';
import MyCheckList from '../../controls/MyCheckList';
import { Card, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, useMediaQuery } from '@mui/material';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import LogHelper from '../../helpers/LogHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import NotificationActions from '../../actions/NotificationActions';
import MyDate from '../../controls/MyDate';
import moment from 'moment';
import SystemLogBreadcrumbs from './NotificationBreadcrumbs';
import MyDropDown from '../../controls/MyDropDown';
import EmailActions from '../../actions/EmailActions';
import parse from 'html-react-parser';

const RecordDefault = {
  PageSize: enumPagination.PageSize,
  LogTypeCodes: "",
  SentFL: "0",
  ArchiveFL: "0",
  Years: new Date().getFullYear().toString(),
  DateFrom: moment(Date.now()).format("YYYY-MM-DD"),
  DateTo: moment(Date.now()).add(1, 'week').format("YYYY-MM-DD"),
};


export default function NotificationSearch() {

  const [Record, setRecord] = useState(RecordDefault);
  const [YesNoAllData, setYesNoAllData] = useState([]);
  const [LookupData, setLookupData] = useState([]);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za Responsive
  const largeScreen = useMediaQuery("(min-width:600px)");

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + '/' + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);



  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */
  const setPageMsg = (state, response, customSuccessMsg) => {

    if (state == enumPage.LoadStart) {
      setMessageData((a) => ({ ...a, Message: 'Please wait...', Loading: true, HasInfo: true, HasError: false }));
    }
    else if (state == enumPage.LoadSuccess) {
      setMessageData((a) => ({ ...a, Loading: false, Message: '', HasError: false }));
    }
    else if (state == enumPage.LoadStartWithMsg) {
      setMessageData((a) => ({ ...a, Loading: true, Message: customSuccessMsg, HasInfo: true }));
    }
    else if (state == enumPage.LoadSuccessWithMsg) {
      setMessageData((a) => ({ ...a, Loading: false, Message: customSuccessMsg }));
    }
    else if (state == enumPage.ErrorServer) {
      if (response.ValidationMessages) {
        setRecord((prevState) => ({ ...prevState, ValidationMessages: response.ValidationMessages })); //za da se reflekgiraat po ZSNText,
      }
      setMessageData((a) => ({ ...a, HasError: true, Message: response.error_description, Loading: false }));
    }
    else if (state == enumPage.SaveSuccess) {
      setMessageData((a) => ({ ...a, Loading: false, HasError: false, Message: enumSharedMsgs.Save }));
    }
    else if (state == enumPage.ActionSuccessWithMsg) {
      setMessageData((a) => ({ ...a, Loading: false, HasError: false, Message: customSuccessMsg }));
    }
  };

  const initData = () => {
    async function fetchData() {

      //NAJVAZNO da napravi prvo reset na s'e
      setIsInitDone(false);
      setRecord(RecordDefault);
      setPageMsg(enumPage.LoadStart);

      let responses = await new NotificationActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {

        let lookupData = responses[0].data;
        let YesNoAllData = responses[1].data;

        setLookupData(lookupData);
        setYesNoAllData(YesNoAllData);

        //so ova ke povika search preku useEffect
        setIsInitDone(true);

        setPageMsg(enumPage.LoadSuccess);
      }
      else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }
    fetchData();
  };

  const searchData = () => {
    async function fetchData() {
      setPageMsg(enumPage.LoadStart);

      let responses = await new NotificationActions().SearchAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;

        setRecords(records); //za da se reflekgiraat po ZSNText,
        setPageMsg(enumPage.LoadSuccess);

      } else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }

    fetchData();
  };

  const sendNotifications = () => {
    async function fetchData() {
      setPageMsg(enumPage.LoadStartWithMsg, null, "Emails are sending, please wait...");

      let responses = await new EmailActions().SendNotificationsAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;

        setPageMsg(enumPage.ActionSuccessWithMsg, null, "Emails were sent");

      } else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }

    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };


  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <React.Fragment>
        <Button sx={{ ml: 3, mr: 2, mt: 3.5 }} variant="contained" color="success" size="small" disabled={MessageData.Loading} onClick={() => searchData()}>Search</Button>

        <Button sx={{ mr: 0, mt: 3.5 }} variant="contained" color="secondary" size="small" disabled={MessageData.Loading} onClick={() => sendNotifications()}>Send Email</Button>
      </React.Fragment>
    )
  };


  const styleTDHeader = { fontSize: 13, fontWeight: "bold" };
  const styleTD = { fontSize: 13 };


  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">

      <SystemLogBreadcrumbs page={enumBreadcrumbPage.Search} disabled={MessageData.Loading} />

      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={largeScreen ? 3 : 12} >
              <MyCheckList Id='LogTypeCodes'
                Record={Record}
                PlaceHolder='Notification Types'
                HandleChange={handleChange}
                ValidationMessages={Record.ValidationMessages}
                Options={LookupData}
                LookupTypeId={enumLookupTypes.Notifications}
                Required={true} />
            </Grid>
            <Grid item xs={largeScreen ? 1.6 : 12} >
              <MyDate Id='DateFrom' PlaceHolder='Date From' Record={Record} HandleChange={handleChange} />
            </Grid>
            <Grid item xs={largeScreen ? 1.6 : 12} >
              <MyDate Id='DateTo' PlaceHolder='Date To' Record={Record} HandleChange={handleChange} />
            </Grid>
            <Grid item xs={largeScreen ? 1.5 : 12} >
              <MyDropDown Id='SentFL' Record={Record} PlaceHolder='Sent' HandleChange={handleChange} Options={YesNoAllData} />
            </Grid>
            <Grid item xs={largeScreen ? 1.5 : 12} >
              <MyDropDown Id='ArchiveFL' Record={Record} PlaceHolder='Archived' HandleChange={handleChange} Options={YesNoAllData} />
            </Grid>
            <Grid>
              <ToolbarButtons />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} >
              <Typography variant="subtitle1" align='left'>Total Records found: {Records.length}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>



      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={styleTDHeader}>Scheduled Date</TableCell>
              <TableCell sx={styleTDHeader}>Type/Name/Description</TableCell>
              <TableCell sx={styleTDHeader}>Receiver</TableCell>
              <TableCell sx={styleTDHeader}>Sent</TableCell>
              <TableCell sx={styleTDHeader}>Archived</TableCell>
              <TableCell sx={styleTDHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow key={row.NotificationID} sx={{ '&:last-child td, &:last-child th': { border: 0 }, verticalAlign: "top" }}>
                <TableCell sx={styleTD}>
                  {index + 1}. {moment(row.noScheduledDate).format("DD/MM/YYYY HH:mm")}<br /><br />
                  {row.ExpirationDateName}
                </TableCell>
                <TableCell sx={styleTD}>
                  <b>{row.TypeName}</b><br />
                  {row.noName}<br /><br />
                  {parse(row.noDescription)}<br /><br />
                </TableCell>
                <TableCell sx={styleTD}>{row.noEmail}  {row.noEmailCC != '' ? " / " + row.noEmailCC : ""}
                </TableCell>
                <TableCell sx={styleTD}>
                  {row.SentFLName}
                </TableCell>
                <TableCell sx={styleTD}>
                  {row.ArchiveFLName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </Typography>
  );
}

