import * as React from "react";
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsIcon from "@mui/icons-material/Notifications";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AuthenticateHelper from "./helpers/AuthenticateHelper";
import NavigationHelper from "./helpers/NavigationHelper";
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { enumSQLS, enumRoutes, enumBreadcrumbPage } from "./helpers/common.constants";
import AcquisitionBreadcrumbs from './pages/Acquisition/AcquisitionBreadcrumbs';
import Badge from '@mui/material/Badge';
import ActionPointsActions from './actions/ActionPointsActions';
import MyGeneralSearch from './controls/MyGeneralSearch';

function Header(props) {
  const { onDrawerToggle, recordData } = props;
  const location = useLocation();
  const [PageTitle, setPageTitle] = useState('');
  const [AvatarInfo, setAvatarInfo] = useState('');
  const [FullName, setFullName] = useState('');
  const [TotalActionPoints, setTotalActionPoints] = useState(0);
  const navigate = useNavigate();

  //Ovoj Hook se pali samo pri novi zapisi i header-ot go menuva od //od Acquisition/new vo Acquisition/edit  itn
  // React.useEffect(() => {
  //   if (recordData) {
  //     if (recordData.refreshFL) {
  //       let pathNameModified = location.pathname.toLowerCase().replace("/new", "/edit");
  //       let pageTitle = new NavigationHelper(props.Menu).GetPageTitle(pathNameModified);
  //       setPageTitle(pageTitle);
  //     }
  //   }
  // }, [recordData]); //default


  React.useEffect(() => {
    if (props.Menu.length > 0) {
      let pageTitle = new NavigationHelper(props.Menu).GetPageTitle(location.pathname);
      setPageTitle(pageTitle);
    }
  }, [props.Menu]); //default

  React.useEffect(() => {
    if (props.Menu.length > 0) {
      let pageTitle = new NavigationHelper(props.Menu).GetPageTitle(location.pathname);
      setPageTitle(pageTitle);
    }

    //proveri dali ima action points i setiraj za niv
    GetTotalActionPointsCount();

  }, [location.pathname + '/' + location.search]); //default

  React.useEffect(() => {
    let fullName = new AuthenticateHelper().GetFullName();
    setFullName(fullName);

    let avatarInfo = fullName.substring(0, 1);
    setAvatarInfo(avatarInfo);
  }, []); //za da go zeme Acquisition/New  + 1682892451704 od [http://localhost:3000/Acquisition/New?1682892451704]


  const GetTotalActionPointsCount = () => {
    async function fetchData() {

      let responses = await new ActionPointsActions().GetTotalActionPointsAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {

        let totalActionPoints = responses[0].data;
        setTotalActionPoints(totalActionPoints)
      }
    }


    fetchData();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMyProfile = () => {
    navigate(enumRoutes.Main_MyProfile + '?' + new Date().getTime()); //za da forsira REFRESH);
  };

  const handleChangePassword = () => {
    window.open("https://accounts.google.com/v3/signin/challenge/pwd?TL=AJeL0C4jq2sazonJOFwfwlGT-qjVLZyc7ONA9hpm9zcCVR9GA58Si5cdickVILUy&cid=1&continue=https://myaccount.google.com/signinoptions/password&flowName=GlifWebSignIn&hl=en_US&ifkv=AYZoVhcJxELozZeasa7lTAN1XcOhA2aSRAKh1TTf9jfcZvkiQ4789gOUmt74RhBvSFi2dlHLL5y2rw&kdi=CAM&rart=ANgoxccujemft-yZT4Pj4TzYS9J7iq6fTfb8zgcNSxSDNz4CHucbLghcGgZfQOHK5RkPe3m378CZN9RRtWlFlmN7f8iu_MV8mg&rpbg=1&sarp=1&scc=1&service=accountsettings", "_blank");
    //navigate(enumRoutes.User_ChangePassword);
  };

  const handleLogout = () => {
    navigate(enumRoutes.Security_Logout);
  };

  const handleActionPoints = () => {
    navigate(enumRoutes.Main_ActionsPoints);
  };


  const handleGeneralSearch = (e) => {
    const { name, value } = e.target;
    if (value === 0) return;
    navigate(value);
    console.log(name + ":" + value);
  };


  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>


            <Grid item xs />
            {!new AuthenticateHelper().IsStaff() && <Grid item xs>
              <MyGeneralSearch Id='GeneralSearchID'
                SkipPlaceHolderSuffix={true}
                //Record={Record}
                PlaceHolder='Search ...'
                HandleChange={handleGeneralSearch}
                SQLGetList={enumSQLS.KEY_LIST_GeneralSearch}
                Variant="standard"
                HideTriangle={true}
                Margin="none"
              />
            </Grid>
            }
            <Grid item xs />
            <Grid item>
              <Tooltip title="Action Points">
                <Badge color="secondary" badgeContent={TotalActionPoints} showZero onClick={handleActionPoints} size="small">
                  <NotificationsActiveIcon />
                </Badge>
              </Tooltip>

              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>{AvatarInfo}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem>
                  {FullName}
                </MenuItem>
                <MenuItem onClick={handleMyProfile}>
                  <Avatar /> My Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleChangePassword}>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>


      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h2" component="h1">
                {PageTitle}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* <AppBar
        component="div"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Tabs value={0} textColor="inherit">
          <Tab label="Users" />
          <Tab label="Sign-in method" />
          <Tab label="Templates" />
          <Tab label="Usage" />
        </Tabs>
      </AppBar> */}
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
