// @ts-check 
import Typography from '@mui/material/Typography';

import { Breadcrumbs } from '@mui/material';
import { Link } from "react-router-dom";
import { enumBreadcrumbPage, enumRoutes } from '../../helpers/common.constants';
import { useEffect, useState } from 'react';

export default function NotificationBreadcrumbs({page, Id, disabled}) {

  
  const [showPage, setShowPage] = useState(false);

  //1 --> Go zapaluva pri sekoja promena na InputValue i go formatira vo EUR format 
  useEffect(() => {
      if (!disabled)
      {
        setShowPage(true);
      }
  }, [disabled]); //default


  const RenderPage = () => {
      if (!showPage) 
      {
        return <div/>
      }
      if (page === enumBreadcrumbPage.Search)
      {
        return <Search/>;
      }
      else if (page === enumBreadcrumbPage.Master)
      {
        return <Master/>;
      }
      return <div/>
  }
 
  const Master = () => {
    return (
      <Breadcrumbs color="primary"  variant="body2"  aria-label="breadcrumb">
        <Link to={enumRoutes.Acquisition_Dashboard}>Home</Link>
        <Link to={enumRoutes.Notification_Search}>Search</Link>
        <Typography variant="body2"  color="text.primary">Notification</Typography>
      </Breadcrumbs>
    );
  }

  const Search = () => {
    return (
      <Breadcrumbs color="primary"  variant="body2"  aria-label="breadcrumb">
        <Link to={enumRoutes.Acquisition_Dashboard}>Home</Link>
        <Typography variant="body2"  color="text.primary">Search</Typography>
      </Breadcrumbs>
    );
  }


  return (
    <RenderPage/>
  )
}
