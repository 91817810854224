// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";

import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumPage,
  enumRoutes
} from "../../helpers/common.constants";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import moment from "moment";
import FinProjectActions from "../../actions/FinProjectActions";
import { MessageHelper } from "../../helpers/MessageHelper";
import FinHeaderButtons from "./FinHeaderButtons";
import FinProjectHeader from "./FinProjectHeader";
import React from "react";
import FinEmployeeActions from "../../actions/FinEmployeeActions";
import EmployeeActions from "../../actions/EmployeeActions";
import Link from "@mui/material/Link";

const RecordDefault = {
  fpProjectID: 0,
  fpYear: moment(Date.now()).format("YYYY"),
  fpCode: "",
  fpName: "",
  fpDescription: "",
  fpStartDate: moment().startOf('year').format('YYYY-MM-DD'),
  fpEndDate: moment().endOf('year').format('YYYY-MM-DD'),
  fpVersionNo: 1,
  fpVersionDate: '1900-01-01',
  fpHolderCount: 0,
  fpOwnerID: -1,
  fpOwnerName: "",
  fpOwnerEmail: "",

  fpHolderID: -1,
  fpHolderName: "",
  fpHolderEmail: "",
  fpHolderID2: -1,
  fpHolderName2: "",
  fpHolderEmail2: "",
  fpHolderID3: -1,
  fpHolderName3: "",
  fpHolderEmail3: "",
  fpActiveFL: false,
  fpAmount: "",
  fpVerifiedFL: false,
  IsLocked: false
};

export default function FinEmployeePlanning(args) {
  // console.error("AcquisitionManage - ARGS");
  // console.error(args);

  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [IsInitDone, setIsInitDone] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);

  const [Employee, setEmployee] = useState({});
  const [LedgerByEmployee, setLedgerByEmployee] = useState([]);
  const [LedgerByProject, setLedgerByProject] = useState([]);
  const [LedgerByBudget, setLedgerByBudget] = useState([]);
  const [LedgerByAllocation, setLedgerByAllocation] = useState([]);

  //Da proveri dali e nov zapis ili postoecki
  const { EmployeeID, Year } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {

      setRecord(RecordDefault);
      setIsNewRecord(true);

      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      if (EmployeeID) {
        let payload = { StaffIDs: EmployeeID, TypeView: 3, Year: Year }; //3 -> Show per Project
        let response1 = await new FinEmployeeActions().GetEmployeeCoverByProjectAsync(payload); // await LookupActions.InitAsync();

        let payload2 = { StaffIDs: EmployeeID, TypeView: 2, Year: Year }; //3 -> Show per Budget
        let response2 = await new FinEmployeeActions().GetEmployeeCoverByBudgetAsync(payload2); // await LookupActions.InitAsync();

        let payload3 = { StaffIDs: EmployeeID, TypeView: 1, Year: Year }; //1 -> Show per Allocation
        let response3 = await new FinEmployeeActions().GetEmployeeCoverByAllocationAsync(payload3); // await LookupActions.InitAsync();

        let payload4 = { StaffIDs: EmployeeID, TypeView: 4, Year: Year }; //1 -> Show per Employee -> TOP SUMMARY
        let response4 = await new FinEmployeeActions().GetEmployeeCoverByEmployeeAsync(payload4); // await LookupActions.InitAsync();        


        if (!response1.error) {
          let recordDb = response1[0].data;
          setLedgerByProject(recordDb);

          let recordDb2 = response2[0].data;
          setLedgerByBudget(recordDb2);

          let recordDb3 = response3[0].data;
          setLedgerByAllocation(recordDb3);

          let recordDb4 = response4[0].data;
          setLedgerByEmployee(recordDb4);

          setRecord(recordDb);
          setIsNewRecord(false);

          //sega loadiraj EMployeee Details
          let payloadEmployee = { eyEmployeeID: EmployeeID }; //1 -> Show per Employee -> TOP SUMMARY
          let responseEmployee = await new EmployeeActions().EditAsync(payloadEmployee); // await LookupActions.InitAsync();    

          if (!responseEmployee.error) {

            setEmployee(responseEmployee[0].data);

            //Load Finished
            result = msgHelper.setPhase(enumPage.LoadSuccess);
            setMessageData(result);
          }
          else {
            //Finished  with Error
            result = msgHelper.setPhase(enumPage.ErrorServer, responseEmployee);
            setMessageData(result);
          }


        } else {
          //Finished  with Error
          result = msgHelper.setPhase(enumPage.ErrorServer, response1);
          setMessageData(result);
        }
      }
      else {
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      }

    }
    fetchData();
  };


  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */


  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">

      <MyAlert {...MessageData} />

      <h3>{Employee.eyFirstName + " " + Employee.eyLastName}</h3>
      <RenderEmployeeLedger Records={LedgerByEmployee} />
      <br />
      <RenderProjectLedger Records={LedgerByProject} />
      <br />
      <RenderBudgetLedger Records={LedgerByBudget} />
      <br />
      <RenderAllocationLedger Records={LedgerByAllocation} />
    </Typography>
  );
}


const RenderEmployeeLedger = ({
  Records,

}) => {
  const { EmployeeID, Year } = useParams();
  return React.useMemo(() => {
    return (
      <TableContainer >
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow >
              <TableCell colSpan={9} align="center" className="tablecell header">
                <Typography variant="h5" align="center">
                  Employee Planning
                </Typography>
              </TableCell>
            </TableRow>


            <TableRow >
            <TableCell colSpan={3} align="center" className="tablecell header">
                <Typography variant="h6" align="center">
                  Days
                </Typography>
              </TableCell>
              <TableCell colSpan={1} align="center" className="tablecell header">
                <Typography variant="h6" align="center" >
                  Spark
                </Typography>
              </TableCell>
              <TableCell colSpan={1} align="center" className="tablecell header">
                <Typography variant="h6" align="center">
                  Mgmt
                </Typography>
              </TableCell>
              <TableCell colSpan={3} align="center" className="tablecell header">
                <Typography variant="h6" align="center">
                  Total
                </Typography>
              </TableCell>

            </TableRow>

            <TableRow>
            <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Allocated Days
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Contract Days
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance
                </Typography>
              </TableCell>


              <TableCell className="tablecell header">
                <Typography variant="h6" align="center" >
                  Planned Alloc.
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Planned Alloc.
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Planned Alloc.
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Contract Salary
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance
                </Typography>
              </TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) =>
              <TableRow key={row.AlocationID} style={index % 2 ? { background: "#E8E8E8" } : { background: "white" }}>
                

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.DaysPlanned, 1)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.DaysAllocated, 1)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.DaysBalance, 1 )}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountSparkPlanned, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountMgmtPlanned, 2)}
                </TableCell>


                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalancePlanned, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalanceAllocated, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalance, 2)}
                </TableCell>

              </TableRow>

            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};


const RenderProjectLedger = ({
  Records,

}) => {
  const { EmployeeID, Year } = useParams();
  return React.useMemo(() => {
    return (
      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow >
              <TableCell colSpan={10} align="center" className="tablecell header">
                <Typography variant="h5" align="center">
                  Project Planning per Employee
                </Typography>
              </TableCell>
            </TableRow>



            <TableRow>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center" >
                  Employee Name
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center" >
                  Project Name
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Allocated
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Contract Salary
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance
                </Typography>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) =>
              <TableRow key={row.AlocationID} className="table-row">
                <TableCell align="left" className="tablecell">

                  {row.ProjectName === '' ? row.StaffName : ''}
                </TableCell>

                <TableCell align="left" className="tablecell">
                  {row.ProjectID > 0 ? <Link
                    sx={{ mr: 1.5 }}
                    href={enumRoutes.Timesheet_FinProject_OverviewPlanning.replace(
                      ":ProjectID",
                      row.ProjectID
                    )}
                    underline="none"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    
                    {row.ProjectName}
                  </Link>
                  :
                  row.ProjectName
                  }

                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalancePlanned, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalanceAllocated, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalance, 2)}
                </TableCell>
              </TableRow>

            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};




const RenderBudgetLedger = ({
  Records,

}) => {
  const { EmployeeID, Year } = useParams();
  return React.useMemo(() => {
    return (
      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow >
              <TableCell colSpan={10} align="center" className="tablecell header">
                <Typography variant="h5" align="center">
                  Budget Planning per Employee
                </Typography>
              </TableCell>
            </TableRow>



            <TableRow>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center" >
                  Employee Name
                </Typography>
              </TableCell>


              <TableCell className="tablecell header">
                <Typography variant="h6" align="center" >
                  Project Name
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center" >
                  Budget Name
                </Typography>
              </TableCell>


              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Allocated
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Contract Salary
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance
                </Typography>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) =>
              <TableRow key={row.AlocationID} className="table-row">
                <TableCell align="left" className="tablecell">
                  {row.ProjectName == '' ? row.StaffName : ''}
                </TableCell>


                <TableCell align="left" className="tablecell">
                  {row.ProjectID > 0 ? <Link
                    sx={{ mr: 1.5 }}
                    href={enumRoutes.Timesheet_FinProject_OverviewPlanning.replace(
                      ":ProjectID",
                      row.ProjectID
                    )}
                    underline="none"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    
                    {row.ProjectName}
                  </Link>
                  :
                  row.ProjectName
                  }

                </TableCell>


                <TableCell align="left" className="tablecell">
                  <Link
                    sx={{ mr: 1.5 }}
                    href={enumRoutes.Timesheet_FinAllocation_EditWithArgs.replace(
                      ":ProjectID",
                      row.ProjectID
                    ).replace(
                      ":BudgetID",
                      row.BudgetID
                    ).replace(
                      ":StaffID",
                      row.StaffID
                    )}
                    underline="none"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {row.BudgetName}
                  </Link>


                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalancePlanned, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalanceAllocated, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalance, 2)}
                </TableCell>
              </TableRow>

            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};





const RenderAllocationLedger = ({
  Records,

}) => {
  const { EmployeeID, Year } = useParams();
  return React.useMemo(() => {
    return (
      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow >
              <TableCell colSpan={11} align="center" className="tablecell header">
                <Typography variant="h5" align="center">
                  Allocation Planning per Employee
                </Typography>
              </TableCell>
            </TableRow>


            <TableRow>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center" >
                  Employee Name
                </Typography>
              </TableCell>

              <TableCell className="tablecell header">
                <Typography variant="h6" align="center" >
                  Project Name / Budget Name
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center" >
                  Allocated
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Contract Salary
                </Typography>
              </TableCell>
              <TableCell className="tablecell header">
                <Typography variant="h6" align="center">
                  Running Balance
                </Typography>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) =>
              <TableRow key={row.AlocationID} className="table-row">
                <TableCell align="left" className="tablecell">
                  {row.CaseID === 2 ? row.AllocationName : ''}
                </TableCell>

                <TableCell align="left" className="tablecell">
                  {row.CaseID === 1 ? row.AllocationName : ''}
                </TableCell>



                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalancePlanned, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalanceAllocated, 2)}
                </TableCell>

                <TableCell align="right" className="tablecell">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.AmountBalance, 2)}
                </TableCell>
              </TableRow>

            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};
