// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumPage,
  enumPagination
} from "../../helpers/common.constants";
import {
  Card, CardContent,
  CardHeader,
  Grid
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import ActionPointsActions from "../../actions/ActionPointsActions";

const RecordDefault = {
  PageSize: enumPagination.PageSize,
  OfficeID: 0,
  CountryIDs: "",
  Name: "",
  PhaseIDs: "",
  ActiveFL: "1",
};


export default function ActionPoints() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [YesNoAllData, setYesNoAllData] = useState([]);

  const [ActionPointsData, setActionPointsData] = useState([]);


  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana 
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {

      //Clean all states  
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let Record = {}
      let responses = await new ActionPointsActions().InitAsync(Record); // await LookupActions.InitAsync();
      if (!responses.error) {

        let actionPointData = responses[0].data;

        if (actionPointData.length > 0) {
          setMessageData(result);
          setActionPointsData(actionPointData);


          //Load Finished
          result = msgHelper.setPhase(enumPage.LoadSuccess);
          setMessageData(result);
        }
        else {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithWarningMsg, null, "No action points found for your profile.");
          setMessageData(result);
        }

        setIsInitDone(true);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };


  //****************************************** */
  //GUI DISPLAY
  //****************************************** */

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">

      <MyAlert {...MessageData} />

      <RenderCards Records={ActionPointsData} />

    </Typography >
  );
}


const RenderCards = ({ Records }) => {

  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <Typography variant="body2" align='left'>

        <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              {Records.map((row, index) => (
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    - <Link underline="none" rel="noopener noreferrer" href={row.ActionLink} >{row.ActionText}</Link>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card >
      </Typography>
    );
  }, [Records]);
}