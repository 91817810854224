// @ts-check 
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MyText from '../../controls/MyText';
import MyDropDown from '../../controls/MyDropDown';
import MySearchFilter from '../../controls/MySearchFilter';
import MySearchDialog from '../../controls/MySearchDialog';

import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumLookupTypes, enumPage, enumRoutes, enumSharedMsgs } from '../../helpers/common.constants';
import AcquisitionActions from '../../actions/AcquisitionActions';
import Button from '@mui/material/Button';
import MyCheckList from '../../controls/MyCheckList';
import { Card, CardActions, CardContent, Grid } from '@mui/material';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from '../../helpers/CurrencyHelper';
import MyMoney from '../../controls/MyMoney';
import MyNumber from '../../controls/MyNumber';
import SystemUserActions from '../../actions/SystemUserActions';
import MyCheckbox from '../../controls/MyCheckbox';
import { AuthenticateService } from '../../services/AuthenticateService';
import AuthenticateActions from '../../actions/AuthenticateActions';
import UserActions from '../../actions/UserActions';
import SystemUserBreadcrumbs from './SystemUserBreadcrumbs';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';

const RecordDefault = {
  Id: '',
  UserName: "",
  FullName: "",
  Email: "",
  Password: "",
  ConfirmPassword: "",
  OldPassword: "",
  IsEnabled: true,
  Role: "",
  RowID: 0
};

export default function SystemUserOverview(args) {


  //Page MAIN Hooks
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);

  //Da proveri dali e nov zapis ili postoecki
  const { UserId } = useParams();

  //za Navigacija 
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();


  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    editData();
  }, [location.pathname + '/' + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */

  const setPageMsg = (state, response) => {

    if (state == enumPage.LoadStart) {
      setMessageData((a) => ({ ...a, Message: '', Loading: true }));
    }
    else if (state == enumPage.LoadSuccess) {
      setMessageData((a) => ({ ...a, Loading: false }));
    }
    else if (state == enumPage.ErrorServer) {
      if (response.ValidationMessages) {
        setRecord((prevState) => ({ ...prevState, ValidationMessages: response.ValidationMessages })); //za da se reflekgiraat po ZSNText,
      }
      setMessageData((a) => ({ ...a, HasError: true, Message: response.error_description, Loading: false }));
    }
    else if (state == enumPage.SaveSuccess) {
      setMessageData((a) => ({ ...a, Loading: false, HasError: false, Message: enumSharedMsgs.Save }));
    }
  };

  const editData = () => {
    async function fetchData() {

      setRecord(RecordDefault);
      setPageMsg(enumPage.LoadStart);

      let responses = await new SystemUserActions().EditAsync({ Id: UserId }); // await LookupActions.InitAsync();
      if (!responses.error) {

        let userData = responses[0].data;
        setRecord(userData);
        setPageMsg(enumPage.LoadSuccess);
      }
      else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }


    fetchData();
  };


  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const HeaderButtons = () => {
    return (
      <Grid container align="left" sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }} align="center">
        <Grid item xs={12} spacing={1} >
          <Button sx={{ mr: 2 }} variant="contained" startIcon={<BrightnessAutoIcon />} size="small" disabled={MessageData.Loading} onClick={() => navigate(enumRoutes.SystemUser_Edit.replace(":UserId", Record.Id))}>
            Manage User
          </Button>
        </Grid>
      </Grid>
    )
  };




  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">

      <SystemUserBreadcrumbs page={enumBreadcrumbPage.Overview} Id={Record.Id} disabled={MessageData.Loading} />

      <MyAlert {...MessageData} />

      <HeaderButtons />

      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}><Typography variant="h6" sx={{ fontWeight: 'bold' }}>User Details</Typography></Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Name:</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1">{Record.FullName}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Email:</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1">{Record.Email}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Role:</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1">{Record.Role}</Typography>
            </Grid>
          </Grid>

        </CardContent>
      </Card>

      <MyAlert {...MessageData} />


    </Typography>
  );
}
