// @ts-check 
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MyText from '../../controls/MyText';
import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumLookupTypes, enumPage, enumPagination, enumRoutes, enumSharedMsgs } from '../../helpers/common.constants';
import DeclarationDiemActions from '../../actions/DeclarationDiemActions';
import Button from '@mui/material/Button';
import MyCheckList from '../../controls/MyCheckList';
import { Box, Breadcrumbs, Card, CardActions, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, useMediaQuery } from '@mui/material';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import LogHelper from '../../helpers/LogHelper';
import MyDate from '../../controls/MyDate';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import DeclarationBreadcrumbs from './DeclarationBreadcrumbs';
import NotesIcon from '@mui/icons-material/Notes';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import { MessageHelper } from '../../helpers/MessageHelper';
import MyNumber from '../../controls/MyNumber';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/HighlightOff';
import MyDropDown from '../../controls/MyDropDown';
import MyMoney from '../../controls/MyMoney';
import DeclarationActions from '../../actions/DeclarationActions';

const RecordDefault =
{
  "dfDeclarationFeeID": 0,
  "dfDeclarationID": 0,
  "dfTypeID": 0,
  "dfName": "",
  "dfDescription": "string",
  "dfProjectName": "string",
  "dfBudgetName": "",
  "dfFee": 0,
  "dfCurrency": "EUR",
  "dfFeeRate": 1,
  "dfFeeEUR": 0,
  "dfStartDate": moment(new Date()).toISOString(),
  "dfEndDate": moment(new Date()).add(1, 'days').toISOString(),
  "deDiemDoc1": ""
};


export default function DeclarationDiemManage() {

  const [LookupData, setLookupData] = useState([]);

  const [DiemTypeData, setDiemTypeData] = useState([]);
  const [BudgetData, setBudgetData] = useState([]);
  const [DeclarationData, setDeclaration] = useState(RecordDefault);
  const [Record, setRecord] = useState(RecordDefault);
  const [InitialRecord, setInitialRecord] = useState(RecordDefault);

  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const { DeclarationID } = useParams();
  const [IsInitDone, setIsInitDone] = useState(false);
  const [ForceSave, setForceSave] = useState(false);
  const [SumRecord, setSumRecord] = useState([]);

  //za Navigacija 
  const navigate = useNavigate();

  //za poraki pri loadiranje na strana 
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Iniicijalno povikaj initData i ako zavrsi se kako sto treba ke go setira IsInitDone: true koj sto ke go zapali hook-ot IsInitDone
  React.useEffect(() => { initData(); }, []); //default

  React.useEffect(() => {
    if (ForceSave) {
      saveData();
    }
  }, [ForceSave]);



  const initData = () => {
    async function fetchData() {

      //Clean all states  
      setIsInitDone(false);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let payload = { deDeclarationID: DeclarationID, ldTypeID: enumLookupTypes.DiemTypes }
      let responses = await new DeclarationDiemActions().InitAsync(payload); // await LookupActions.InitAsync();
      if (!responses.error) {

        let perDiemTypesData = responses[0].data;
        let budgetData = responses[1].data;
        let declarationData = responses[2].data;

        if (declarationData.deSubmittedFL)
        {
          navigate(enumRoutes.Declaration_Submit.replace(":DeclarationID", declarationData.deDeclarationID));
          return;
        }
        setDiemTypeData(perDiemTypesData);
        setBudgetData(budgetData);
        setDeclaration(declarationData);

        setRecord((prevState) => ({ ...prevState, dfDeclarationID: DeclarationID }));

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //ako se desi greska ke si se napise samata
        await GetByDeclarationIDAsync();
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  async function GetByDeclarationIDAsync() {

    //sega loadiraj Contracts by employee
    let payload = { deDeclarationID: DeclarationID };
    let responses = await new DeclarationDiemActions().GetByDeclarationIDAsync(payload);
    if (!responses.error) {
      //Load Finished
      let recordsData = responses[0].data;
      setRecords(recordsData);

      let sumItemAmount = 0;
      if (recordsData.length > 0) {
        sumItemAmount = recordsData.map((item) => item.dfFeeEUR).reduce((a, b) => a + b);
      }
      setSumRecord({ Amount: sumItemAmount });

      let result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
      return true;
    }
    else {
      //Load with Error
      let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
      return false;
    }
  }


  const saveData = () => {
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let response = await new DeclarationDiemActions().SaveAsync(Record);

      if (!response.error) {

        //Refreshiraj cela Strana i pocekaj da zavrsi i posle ispisi SAVE poraka  
        const isOk = await GetByDeclarationIDAsync();

        if (isOk) {
          //Load Finished
          result = msgHelper.setPhase(enumPage.SaveSuccess);
          setMessageData(result);
          setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages da gi izbrise

          setRecord(InitialRecord);
          setRecord((prevState) => ({ ...prevState, dfDeclarationID: DeclarationID }));

          setForceSave(false);
        }
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages 
      }
    }

    fetchData();
  };


  const downloadData = () => {
    async function fetchData() {

      let payload = "?ID=" + DeclarationID;
      let response = await new DeclarationDiemActions().DownloadDiemAsync(payload);

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', DeclarationData.deDiemDoc1); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
    fetchData();
  };

  const deleteData = (Id) => {
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      Record.dfDeclarationFeeID = Id;
      let response = await new DeclarationDiemActions().DeleteAsync(Record);

      if (!response.error) {

        //Refreshiraj cela Strana i pocekaj da zavrsi i posle ispisi SAVE poraka  
        const isOk = await GetByDeclarationIDAsync();

        if (isOk) {
          //Load Finished
          result = msgHelper.setPhase(enumPage.DeleteSuccess);
          setMessageData(result);

          setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages da gi izbrise
          setRecord(InitialRecord);
          setRecord((prevState) => ({ ...prevState, dfDeclarationID: DeclarationID }));

          setForceSave(false);
        }
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages 
      }
    }

    fetchData();
  };




  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeDates = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));

    var startDate = moment(Record.dfStartDate).format("YYYY/MM/DD");
    var endDate = moment(Record.dfEndDate).format("YYYY/MM/DD");
    if (name === "dfStartDate") {
      startDate = moment(value).format("YYYY/MM/DD");
    }

    if (name === "dfEndDate") {
      endDate = moment(value).format("YYYY/MM/DD");
    }

    startDate = moment(startDate);
    endDate = moment(endDate);

    var days = endDate.diff(startDate, 'days'); // 1

    setRecord((prevState) => ({ ...prevState, [name]: value, 'dfFeeRate': days }));

    let diemType = Record.dfName;
    if (diemType && diemType !== '') {
      let currentOption = DiemTypeData.find((x) => x.ldValue === diemType) || []
      if (currentOption) {
        let dfFee = parseFloat(currentOption.ldValue2);
        let dfFeeRate = days;
        let dfFeeEUR = parseFloat(parseFloat(dfFee) * parseFloat(dfFeeRate));
        setRecord((prevState) => ({ ...prevState, 'dfFee': dfFee, 'dfFeeEUR': dfFeeEUR }));
      }
    }

    //proveri dali kalkculaciite se vikaat od stisnato KOPCE: SAVE
    if (e.relatedTarget) {
      if (e.relatedTarget.name === "btnSave") {
        setForceSave(true);
      }
    }
  };

  const handleChangeDiemType = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);

    let currentOption = DiemTypeData.find((x) => x.ldValue === value) || []

    if (currentOption) {
      let dfFee = parseFloat(currentOption.ldValue2);
      let dfFeeRate = parseFloat(Record.dfFeeRate);
      let dfFeeEUR = parseFloat(parseFloat(dfFee) * parseFloat(dfFeeRate));

      setRecord((prevState) => ({ ...prevState, [name]: value, 'dfFee': dfFee, 'dfFeeEUR': dfFeeEUR }));
    }


    //proveri dali kalkculaciite se vikaat od stisnato KOPCE: SAVE
    if (e.relatedTarget) {
      if (e.relatedTarget.name === "btnSave") {
        setForceSave(true);
      }
    }
  };


  //******************q************************ */
  //GUI DISPLAY
  //****************************************** */
  const ActionButtons = () => {
    return (
      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          <Button variant="contained" name="btnSave" color="success" size="small" disabled={MessageData.Loading || DeclarationData.deSubmittedFL} onClick={() => saveData()}>Save</Button>
        </Grid>
      </Grid>
    )
  };

  const HeaderButtons = () => {
    return (
      <Grid container sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }} align="center">
        
        <Grid item xs={12} spacing={1} >
        <Button sx={{ mr: 2 }} variant="contained" startIcon={<VisibilityIcon />} size="small" disabled={MessageData.Loading} onClick={() => navigate(enumRoutes.Declaration_Edit.replace(":DeclarationID", DeclarationID))}>
              Declaration
            </Button>

          <Button sx={{ mr: 2 }} variant="contained" startIcon={<VisibilityIcon />} size="small" disabled={MessageData.Loading} onClick={() => navigate(enumRoutes.Declaration_Cost_Edit.replace(":DeclarationID", DeclarationID))}>
            Costs
          </Button>

          <Button sx={{ mr: 2 }} variant="contained" startIcon={<VisibilityIcon />} size="small" disabled={MessageData.Loading} onClick={() => navigate(enumRoutes.Declaration_Diem_Edit.replace(":DeclarationID", DeclarationID))}>
            Diems
          </Button>

          <Button sx={{ mr: 2 }} variant="contained" startIcon={<VisibilityIcon />} size="small" disabled={MessageData.Loading} onClick={() => navigate(enumRoutes.Declaration_Submit.replace(":DeclarationID", DeclarationID))}>
            Preview & Submit
          </Button>
        </Grid>
      </Grid>
    )
  };


  const handleFileUpload = (e) => {
    const { files, name } = e.target;

    const file = files[0];

    if (file) {
      uploadData();
    }

    async function uploadData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart)
      setMessageData(result);

      let payload = { FileData: file, FileName: file.name, ID: DeclarationID };
      let responses = await new DeclarationDiemActions().UploadAsync(payload);

      if (!responses.error) {

        //Refreshiraj samo delot za Declaration
        let payload = { deDeclarationID: DeclarationID };
        responses = await new DeclarationActions().EditAsync(payload); // await LookupActions.InitAsync();
        if (!responses.error) {

          let recordDb = responses[0].data;
          setDeclaration(recordDb);

          //Load Finished
          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Supporting document successfully uploaded, you can preview & submit now if you are ready with all your costs/diems.");
          setMessageData(result);
        }
        else {
          let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
          setMessageData(result);
        }
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages 
      }
    }

  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3" display="block">{DeclarationData.deName}</Typography>
        </Grid>
      </Grid>

      <DeclarationBreadcrumbs page={enumBreadcrumbPage.Section_2} Id={DeclarationID} disabled={MessageData.Loading} />
      <MyAlert {...MessageData} />

      <HeaderButtons />
      <ActionButtons />

      <Grid container align="left" sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>Diem Detail</b>
          </Typography>
        </Grid>
      </Grid>


      <Grid container align="left">
        <Grid item xs={12} md={3}>
          <MyDate Id='dfStartDate' PlaceHolder='Start Date' Record={Record} HandleChange={handleChangeDates} />
        </Grid>

        <Grid item xs={12} md={3}>
          <MyDate Id='dfEndDate' PlaceHolder='End Date' Record={Record} HandleChange={handleChangeDates} />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ borderColor: 'grey.500', pb: 1 }}>
        <Grid item xs={12} md={6}>
          <MyDropDown Id='dfBudgetName'
            Record={Record}
            PlaceHolder='Budget'
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Options={BudgetData}
            Required={true} />
        </Grid>

        <Grid item xs={12} md={6}>
          <MyDropDown Id='dfName'
            Record={Record}
            PlaceHolder='Diem Type'
            HandleChange={handleChangeDiemType}
            ValidationMessages={Record.ValidationMessages}
            Options={DiemTypeData}
            OptionsText='ldValue'
            OptionsValue='ldValue'
            Required={true} />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ borderColor: 'grey.500', pb: 1 }}>
        <Grid item xs={12} md={2}>
          <MyNumber Id='dfFee' PlaceHolder='Per Day Fee' Record={Record} TextAlign="right" Disabled={true} />
        </Grid>

        <Grid item xs={12} md={2}>
          <MyText Id='dfFeeRate' PlaceHolder='Days' Record={Record} TextAlign="right" Disabled={true} />
        </Grid>
        <Grid item xs={12} md={2}>
          <MyMoney Id='dfFeeEUR' PlaceHolder='Total' Record={Record} TextAlign="right" Disabled={true} />
        </Grid>
      </Grid>

      <Grid container align="left" sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>List of Diems</b>
          </Typography>
        </Grid>
      </Grid>


      <TableContainer sx={{ display: { xs: "block", sm: "block", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center"><b>#</b></TableCell>
              <TableCell align="center"><b>Start Date</b></TableCell>
              <TableCell align="center"><b>End Date</b></TableCell>
              <TableCell align="center"><b>Budget</b></TableCell>
              <TableCell align="center"><b>Day Fee</b></TableCell>
              <TableCell align="center"><b>Days</b></TableCell>
              <TableCell align="center"><b>Total (EUR)</b></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow key={row.dfDeclarationFeeID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {index + 1}.
                </TableCell>
                <TableCell align="left">{moment(row.dfStartDate).format("D/MM/YYYY")}</TableCell>
                <TableCell align="left">{moment(row.dfEndDate).format("D/MM/YYYY")}</TableCell>
                <TableCell align="left">{row.dfBudgetName}</TableCell>
                <TableCell align="right">{new CurrencyHelper().ToMoney(row.dfFee)}</TableCell>
                <TableCell align="right">{new CurrencyHelper().ToMoney(row.dfFeeRate)}</TableCell>
                <TableCell align="right">{new CurrencyHelper().ToEuro(row.dfFeeEUR)}</TableCell>
                <TableCell>
                  <Tooltip title="Delete Record" disableFocusListener={true}>
                    <IconButton
                      disableFocusRipple={true}
                      disableTouchRipple={true}
                      size="small"
                      name="details"
                      disabled={MessageData.Loading || DeclarationData.deSubmittedFL}                      
                      onClick={() => deleteData(row.dfDeclarationFeeID)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={6} align="right">
                <b>Total</b>
              </TableCell>
              <TableCell align="right">
                <b>{new CurrencyHelper().ToEuro(SumRecord.Amount)}</b>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container align="left" sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
          <b>Supported Documents (if you have more than one document please uploaded as a zip file)</b>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ my: 0, mx: 2 }} color="text.secondary" >
        <Grid item xs={12} md={12} align="left">
          <Typography variant="subtitle" display="block">
            <span>{DeclarationData.deDiemDoc1 === "" ? "Please upload supported document for your costs    " : "Uploaded file:" + DeclarationData.deDiemDoc1}</span>

            <Button variant="contained" component="label" sx={{ ml: 2, mr: 2 }} disabled={MessageData.Loading || DeclarationData.deSubmittedFL}
              disableFocusRipple={true}
              disableTouchRipple={true}>
              Upload
              <input hidden accept="*" multiple type="file" name="deDiemDoc1" onChange={handleFileUpload} />
            </Button>

            {DeclarationData.deDiemDoc1 !== "" &&
              <Button variant="contained" color="success" onClick={() => downloadData()} disabled={MessageData.Loading || DeclarationData.deSubmittedFL}
                disableFocusRipple={true}
                disableTouchRipple={true}>
                Download
              </Button>
            }
          </Typography>

        </Grid>
      </Grid>



    </Typography>
  );
}
