// @ts-check 
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import MyText from '../../controls/MyText';

import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumData, enumLeaveRequest, enumPage, enumRoutes, enumSharedMsgs } from '../../helpers/common.constants';
import NotificationActions from '../../actions/NotificationActions';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from '../../helpers/CurrencyHelper';
import moment from 'moment';
import MyDateTime from '../../controls/MyDateTime';
import LeaveRequestBreadcrumbs from './LeaveRequestBreadcrumbs';
import { MessageHelper } from '../../helpers/MessageHelper';
import LeaveRequestActions from '../../actions/LeaveRequestActions';
import MyDate from '../../controls/MyDate';
import NotesIcon from '@mui/icons-material/Notes';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MyDropDown from '../../controls/MyDropDown';

const RecordDefault =
{
  "rqRequestID": 0,
  "rqEmployeeID": 0,
  "rqDatetimeStart": moment(Date.now()).format("YYYY-MM-DD HH:mm"),
  "rqDatetimeEnd": moment(Date.now()).format("YYYY-MM-DD HH:mm"),
  "rqTotalHours": 8,
  "rqApproved": true,
  "rqSubmited": true,
  "rqReviewed": true,
  "rqSuperiorID": 0,
  "rqYear": 0,
  "rqLeftHours": 0,
  "rqNote": "",
  "ContractedHours": 0,
  "TakenHours": 0,
  "BalanceHours": 0,
  "SuperiorID": 0,
  "SuperiorName": "",
  "EmployeeFullName": ""
}



const RecordDefaultHourBalance =
{
  "TakenHours": 0,
  "BalanceHours": 0,
  "SuperiorID": 0,
  "SuperiorName": "",
  "ContractedHours": 0
}

export default function LeaveRequestApproval(args) {

  //Page MAIN Hooks
  const [EnableButtons, setEnableButtons] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);
  const [RecordHourBalance, setRecordHourBalance] = useState(RecordDefaultHourBalance);

  //Da proveri dali e nov zapis ili postoecki
  const { RequestID } = useParams();

  //za Navigacija 
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();


  //za poraki pri loadiranje na strana 
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {

    //Check if is existing record and make EditData
    //alert(RequestID);
    if (RequestID) {
      editData();
    }

    //initData();
  }, [location.pathname + '/' + location.search]);


  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */

  const editData = () => {
    async function fetchData() {

      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);
      setEnableButtons(false);
      //setMessageData((a) => ({...a, Loading: true }));

      let payload = { rqRequestID: RequestID };
      let responses = await new LeaveRequestActions().EditAsync(payload); // await LookupActions.InitAsync();
      if (!responses.error) {

        
        
        let recordDb = responses[0].data;
        setRecord(recordDb);
        

        await GetStaffLeaveRequestBalance(recordDb.rqEmployeeID);
        //let result = msgHelper.setPhase(enumPage.LoadSuccess);
        //setMessageData(result);
        
      }
      else {
        let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }

    }
    fetchData();
  };

  
  async function GetStaffLeaveRequestBalance(EmployeeID) {

    //sega loadiraj Contracts by employee
    let payload = { EmployeeID: EmployeeID };
    let responses = await new LeaveRequestActions().GetStaffLeaveRequestBalanceAsync(payload);
    if (!responses.error) {
      //Load Finished
      let recordsData = responses[0].data;
      setRecordHourBalance(recordsData);

      let result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
      setEnableButtons(true);
      return true;
    }
    else {
      //Load with Error
      let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
      return false;
    }
  }

  const approveData = () => {

    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);
      setEnableButtons(false);

      //mora za da moze pravilno da se snimi inaku pravi Validation Error: Status Code: 400
      Record.rqDatetimeStart = moment(Record.rqDatetimeStart).format("YYYY-MM-DDTHH:mm:ss.sssZ");
      Record.rqDatetimeEnd = moment(Record.rqDatetimeEnd).format("YYYY-MM-DDTHH:mm:ss.sssZ");
      Record.rqApproved = true;

      let response = await new LeaveRequestActions().ApprovalAsync(Record);
      if (!response.error) {

        let recordDb = response[0].data;
        setRecord(recordDb);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Leave request approved and email sent to your Staff");
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages da gi izbrise
      }
      else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Samo ovdeka mu treba ako ima Validacioni greski da dodade
        setEnableButtons(true);
      }
    }

    fetchData();
  };


  const disapproveData = () => {

    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);
      setEnableButtons(false);

      //mora za da moze pravilno da se snimi inaku pravi Validation Error: Status Code: 400
      Record.rqDatetimeStart = moment(Record.rqDatetimeStart).format("YYYY-MM-DDTHH:mm:ss.sssZ");
      Record.rqDatetimeEnd = moment(Record.rqDatetimeEnd).format("YYYY-MM-DDTHH:mm:ss.sssZ");
      Record.rqApproved = false;

      let response = await new LeaveRequestActions().ApprovalAsync(Record);
      if (!response.error) {

        let recordDb = response[0].data;
        setRecord(recordDb);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Leave request disapproved and email sent to your Staff");
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages da gi izbrise
      }
      else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Samo ovdeka mu treba ako ima Validacioni greski da dodade
        setEnableButtons(true);
      }
    }

    fetchData();
  };



  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };


  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ActionButtons = () => {
    return (
      <Grid container padding={1}>
        <Grid item xs={6} align="left">
          <Button name="btnSave" variant="contained" color="secondary" size="small" disabled={MessageData.Loading || !EnableButtons || Record.rqApproved} onClick={() => disapproveData()}>Disapprove</Button>
        </Grid>

        <Grid item xs={6} align="right">
          <Button name="btnSave" variant="contained" color="success" size="small" disabled={MessageData.Loading || !EnableButtons || Record.rqApproved} onClick={() => approveData()}>Approve</Button>
        </Grid>
      </Grid>
    )
  };

 

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">

      <LeaveRequestBreadcrumbs page={enumBreadcrumbPage.Master} Id={Record.rqRequestID} disabled={MessageData.Loading} />

      <MyAlert {...MessageData} />

      <ActionButtons />

      <Grid container align="center">
        <Grid item xs={12}>
          <Typography variant="h5">
            {Record.EmployeeFullName && "Leave Request for " +  Record.EmployeeFullName}
          </Typography>
        </Grid>
      </Grid>

      <Grid container align="center">
        <Grid item xs={12}>
          <Typography variant="h5">
            Total Hours : {RecordHourBalance.ContractedHours}, Taken Approved Hours : {RecordHourBalance.TakenHours}, Balance Hours : {RecordHourBalance.BalanceHours}, Superior: {RecordHourBalance.SuperiorName}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} align="left">
          <MyDate Id='rqDatetimeStart' PlaceHolder='Start Date' Record={Record} Disabled={true}
            HandleChange={handleChange}
          />
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12} align="left">
          <MyDate Id='rqDatetimeEnd' PlaceHolder='End Date' Record={Record} Disabled={true}
            HandleChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} align="left">
          <MyDropDown
            Id="rqTypeID"
            Record={Record}
            Disabled={true}
            PlaceHolder="Leave Request Type"
            HandleChange={handleChange}
            Options={enumData.LEAVEREQUEST_TYPES}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText Id='rqTotalHours'
            PlaceHolder='Total Hours'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Disabled={true}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText Id='rqNote'
            PlaceHolder='Please enter note in case of disapproval'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            MultiLine={true}
            Rows={3}
          />
        </Grid>
      </Grid>


      <ActionButtons />

      <MyAlert {...MessageData} />


    </Typography>
  );
}

