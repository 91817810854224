// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumData,
  enumLocalStorageArgs,
  enumLookupTypes,
  enumPage,
  enumPagination,
  enumRoutes,
  enumSQLS,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Box,
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DownloadActions from "../../actions/DownloadActions";
import MyDialogYesNo from "../../controls/MyDialogYesNo";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import FinAllocationActions from "../../actions/FinAllocationActions";
import { ForkRight, Padding } from "@mui/icons-material";
import MyCurrency from "../../controls/MyCurrency";
import ZCurrencyField from "../../controls/ZCurrencyField";
import ZDate from "../../controls/ZDate";
import ZDropDown from "../../controls/ZDropDown";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import moment from "moment";
import ZAutoSuggestField from "../../controls/ZAutoSuggestField";
import FinProjectActions from "../../actions/FinProjectActions";
import FinHeaderButtons from "./FinHeaderButtons";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FinProjectHeader from "./FinProjectHeader";

const RecordDefault = {
  fpProjectID: 0,
  Note: '',
  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeMax,
  TotalRecords: 0,
  TotalPages: 0,
};

const styles = {
  TDSmall: { mt: 0, mb: 0, pt: 0, pb: 0 },
};

export default function FinProjectSubmit() {
  const [Record, setRecord] = useState(RecordDefault);
  const [RecordToSubmit, setRecordToSubmit] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Project, setProject] = useState({});
  const [Records, setRecords] = useState([]);
  const [RecordsSum, setRecordsSum] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);
  const [IsLocked, setIsLocked] = useState(false);
  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [LookupData, setLookupData] = useState([]);
  const { ProjectID } = useParams();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();


  //za Navigacija
  const navigate = useNavigate();


  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);



  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const initData = () => {
    async function fetchData() {
      //Clean all statesLock      setIsInitDone(false);

      let record = RecordDefault;
      record.fpProjectID = ProjectID;
      setRecord(record);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new FinAllocationActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;

        setLookupData(lookupData);

        await GetProjectByIdAsync();
        await GetAllicationAsync();

        //setYearsData(yearsData);
        setIsInitDone(true);

      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };


  async function GetAllicationAsync() {
    //Load Start
    setRecords([]);
    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    let payload = { ProjectID: ProjectID, PageSize: enumPagination.PageSizeMax };
    let responses = await new FinAllocationActions().GetByProjectIDAsync(payload);
    if (!responses.error) {
      const records = responses[0].data;

      //For Pagination purposes
      const pageIndex = responses[0].headers["content-pageindex"];
      const totalRecords = responses[0].headers["content-totalrecords"];
      const totalPages = responses[0].headers["content-totalpages"];
      setRecord((a) => ({
        ...a,
        ["PageIndex"]: pageIndex,
        ["TotalRecords"]: totalRecords,
        ["TotalPages"]: totalPages,
      }));
      setPaginationChange(false); //za da se pali na sleden page
      setIsDoSearch(false); //za da se pali na Search Click

      setRecords(records);

      //napravi sum records
      makeSumRecord(records);

      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    } else {
      //Load with Error
      setIsDoSearch(false); //za da se pali na Search Click
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }

  async function GetProjectByIdAsync() {
    //sega loadiraj Contracts by employee
    let payload = { fpProjectID: ProjectID };
    let responses = await new FinProjectActions().EditAsync(payload);
    if (!responses.error) {
      //Load Finished
      let projectData = responses[0].data;
      setProject(projectData);

      //odredi dali e zaklucen podatokot
      setIsLocked(projectData.PhaseLockFL);
      return true;
    } else {
      //Load with Error
      let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
      return false;
    }
  }

  const handleModalOpenForSubmit = () => {
    setIsModalOpen(true); //Otvori popup
  };

  const handleModalCloseForSubmit = (IsOk) => {
    if (IsOk) {
      async function fetchData() {

        let result = msgHelper.setPhase(enumPage.LoadStart);
        setMessageData(result);

        //StageID: 1 --> Inicijalno e prv
        let payload = { fpProjectID: ProjectID, Note: Record.Note, StageID: 1 };
        let response = await new FinProjectActions().SubmitAsync(payload);
        if (!response.error) {
          navigate(
            enumRoutes.Timesheet_FinProject_Preview.replace(
              ":ProjectID",
              ProjectID
            ).replace(":showMsg", "0")
          );
        }
      }

      fetchData();
    }
    setIsModalOpen(false);
  };

  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };


  const makeSumRecord = (currentRecords) => {
    let newSumRecord = {};

    newSumRecord.faBudgetAmount = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faBudgetAmount)),
      0
    );
    newSumRecord.faMonth1 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth1)),
      0
    );
    newSumRecord.faMonth1 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth1)),
      0
    );
    newSumRecord.faMonth2 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth2)),
      0
    );
    newSumRecord.faMonth3 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth3)),
      0
    );
    newSumRecord.faMonth4 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth4)),
      0
    );
    newSumRecord.faMonth5 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth5)),
      0
    );
    newSumRecord.faMonth6 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth6)),
      0
    );
    newSumRecord.faMonth7 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth7)),
      0
    );
    newSumRecord.faMonth8 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth8)),
      0
    );
    newSumRecord.faMonth9 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth9)),
      0
    );
    newSumRecord.faMonth10 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth10)),
      0
    );
    newSumRecord.faMonth11 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth11)),
      0
    );
    newSumRecord.faMonth12 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth12)),
      0
    );

    newSumRecord.faYearPlanned = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faYearPlanned)),
      0
    );
    
    newSumRecord.faYearCostPlanned = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faYearCostPlanned)),
      0
    );
    setRecordsSum(newSumRecord);
  };





  const handleExcelExport = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new FinAllocationActions().ExportAsync(Record);

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <FinProjectHeader />

      <FinHeaderButtons />

      <RenderTable
        Project={Project}
        RecordsSum={RecordsSum}
        Records={Records}
        Record={Record}
        MessageData={MessageData}
        DataArray={[LookupData]}
        IsLocked={IsLocked}
        onExportExcel={handleExcelExport}
      />


      <Grid container>
        <Grid item xs={12}>
          <MyText Id='Note'
            PlaceHolder='Please enter note that will easier the process of approval...'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            MultiLine={true}
            Rows={3}
          />
        </Grid>
      </Grid>

      <Grid container padding={1}>
        <Grid item xs={12} align="right">
          <Button name="btnSave" variant="contained" color="success" size="small" disabled={MessageData.Loading || IsLocked} onClick={() => handleModalOpenForSubmit()}>Send for Approval</Button>
        </Grid>
      </Grid>

      <MyDialogYesNo
        IsOpen={IsModalOpen}
        HandleModalClose={handleModalCloseForSubmit}
        Title={enumSharedMsgs.WariningMsg}
        Content={"Are you sure you want to submit this project and lock for further editings?"}
      />
    </Typography>
  );
}

const RenderTable = ({
  RecordsSum,
  Records,
  Record,
  DataArray,
  onHandleChange,
  onSaveRow,
  onCloseRow,
  onHandleSave,
  onHandleLockUnlock,
  MessageData,
  IsLocked,
  onEditRow,
  onAddRow,
  onDeleteRow,
  onExportExcel,
}) => {
  return React.useMemo(() => {
    return (
      <TableContainer
        sx={{ display: { xs: "block", sm: "block", md: "block" } }}
      >
        <Table
          sx={{ minWidth: 650, border: "1" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6" align="center">
                  Budget Line
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Staff Member
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Bugdet
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Jan
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Feb
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Mar
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Apr
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  May
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Jun
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Jul
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Aug
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Sep
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Oct
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Nov
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Dec
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Plan. Days
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                Mngmt  Fee
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Plan. Cost
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) =>
              //ako ne e EDIT samo prikazi go RED-ot
              <RenderRow
                row={row}
                index={index}
                onEditRow={onEditRow}
                MessageData={MessageData}
                DataArray={DataArray}
                IsLocked={IsLocked}
              />
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="h6" align="right">
                  Total
                </Typography>
              </TableCell>

              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth1,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth2,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth3,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth4,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth5,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth6,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth7,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth8,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth9,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth10,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth11,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth12,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faYearPlanned,
                    1
                  )}
                </Typography>
              </TableCell>              
              <TableCell></TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(RecordsSum.faYearCostPlanned, 2)}
                </Typography>
              </TableCell>                  
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};


const RenderRow = ({ row, onEditRow, IsLocked }) => {
  //Record = Pagination

  return React.useMemo(() => {
    return (
      <TableRow key={row.eyEmployeeID}>
        <TableCell sx={[styles.TDSmall, { width: "15%" }]}>
          {/* {new Date().getMilliseconds()} */}
          {row.faBudgetName}
        </TableCell>

        <TableCell sx={[styles.TDSmall, { width: "10%" }]}>
          {row.faStaffName}
        </TableCell>

        <TableCell sx={[styles.TDSmall, { textAlign: "right" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.fbAmountPlannedBudget, 2)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth1, 1)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth2, 1)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth3, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth4, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth5, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth6, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth7, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth8, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth9, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth10, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth11, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth12, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faYearPlanned, 1)}
        </TableCell>
        <TableCell sx={[styles.TDSmall, { textAlign: "right" }]}>
          {row.faBudgetMgmtFee > 0 &&
            new CurrencyHelper().ToMoneyFormatDigits(row.faBudgetMgmtFee, 2)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faYearCostPlanned, 2)}
        </TableCell>

      </TableRow>
    );
  }, [
    row.faMonth1,
    row.faMonth2,
    row.faMonth3,
    row.faMonth4,
    row.faMonth5,
    row.faMonth6,
    row.faMonth7,
    row.faMonth8,
    row.faMonth9,
    row.faMonth10,
    row.faMonth11,
    row.faMonth12,
    row.faYearPlanned,
  ]);
};
